import React from 'react';
import { StyledCompanyLogo } from './HeaderLogo.styles';
import { ReactComponent as ServiceLogo } from '../../assets/img/Logo_PeoleFlow.svg';
import CompanyLogo from '../../assets/img/Logo_nomargine.png';

const HeaderLogo = () => {
  return (
    <>
      <ServiceLogo />
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='38'
        height='38'
        viewBox='0 0 38 38'
      >
        <text
          id='by'
          transform='translate(0 31)'
          fill='#e4004f'
          fontSize='20'
          fontWeight='700'
          fontFamily='HelveticaNeue-Bold, Helvetica Neue, Arial Bold, Arial'
        >
          <tspan x='7' y='0'>
            by
          </tspan>
        </text>
      </svg>
      <StyledCompanyLogo src={CompanyLogo} alt='logo' />
    </>
  );
};

export default HeaderLogo;
