import { ActionType, Dispatch } from '../types';
import axios, { AxiosResponse } from 'axios';
import ActionTypes from '../constants/actionTypes';
import { addAlert } from './alerts';

export const getSimilarDate = () => {
  return async (dispatch: Dispatch): Promise<ActionType> => {
    try {
      const response: AxiosResponse = await axios.get(
        'https://peopleflow.aggregator.traisare.com/similardate/trainlocation'
      );
      if (response.data === undefined) {
        throw new Error('類似日付データが見つかりませんでした。');
      }
      return dispatch({
        type: ActionTypes.GET_SIMILAR_DATE,
        payload: { data: response.data },
        meta: {},
      });
    } catch (err) {
      dispatch(
        addAlert({
          type: 'error',
          message: `${err}`,
        })
      );
      return dispatch(getSimilarDateFailed(err));
    }
  };
};

export const getSimilarDateFailed = (e: any) => {
  return {
    type: ActionTypes.GET_SIMILAR_DATE,
    payload: null,
    meta: null,
    error: e,
  };
};

export const updateSelectSimilarDate = (newDate: Date) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SELECT_DATE,
      payload: { newDate },
      meta: {},
    });
  };
};

export const updateSelectSimilarDateIndex = (newIndex: number) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_SELECT_INDEX,
      payload: { newIndex },
      meta: {},
    });
  };
};
