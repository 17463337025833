import React from 'react';
import Typography from '@material-ui/core/Typography';

export type DetailHeaderProps = {
  title: string;
};

const DetailHeader: React.FunctionComponent<DetailHeaderProps> = (props) => {
  const { title } = props;
  return (
    <React.Fragment>
      <Typography variant='h5' component='h2'>
        {title}
      </Typography>
      <hr />
    </React.Fragment>
  );
};

export default DetailHeader;
