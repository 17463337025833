import React from 'react';
import { StyledAnchor, StyledLabel, StyledButtonDiv } from './index.styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

export type MapAttributionProps = {
  component: 'a' | 'label' | 'modal';
  url?: string;
  description?: string;
};

const MapAttribution: React.FunctionComponent<MapAttributionProps> = (
  props
) => {
  const { component, url, description, children } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <React.Fragment>
        <StyledButtonDiv>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </StyledButtonDiv>
      <DialogContent>
        <Typography>
          {description?.split('\n').map((str, index) => (
            <React.Fragment key={index}>{str}<br /></React.Fragment>
          ))}
          <br/>
        </Typography>
      </DialogContent>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {component === 'a' && (
        <StyledAnchor href={url} target='_blank' rel='noopener noreferrer'>
          {children}
        </StyledAnchor>
      )}
      {component === 'label' && <StyledLabel>{children}</StyledLabel>}
      {component === 'modal' && (
        <React.Fragment>
          <StyledAnchor onClick={handleOpen} href={url}>{children}</StyledAnchor>
          <Dialog
            open={open}
            onClose={handleClose}
          >
            {body}
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MapAttribution;
