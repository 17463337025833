import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export const StyledGridContainer = styled(Grid)`
  padding-top: 20px;
`;

export const StyledGridColorPalette = styled(Grid)`
  padding: 6px 3px 6px 3px;
  margin: 3px 0px 3px 0px;
  background-color: rgba(193, 193, 193, 0.5);
`;

export const StyledColorSquare = styled.div.attrs(
  (props: { color: number[] }) => ({
    color: props.color,
  })
)`
  width: 20px;
  height: 10px;
  background-color: ${(props) =>
    props.color
      ? `rgb(${props.color[0]}, ${props.color[1]}, ${props.color[2]})`
      : `#fff`};
`;

export const StyledHelpOutlineIcon = styled(HelpOutlineIcon)`
  padding: 12px 12px 12px 6px;
`;
