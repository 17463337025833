import { Dispatch } from '../types';
import ActionTypes from '../constants/actionTypes';
import makeActionCreator from './actionCreators';

export const updateCenter = (latitude: number, longitude: number) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_CENTER,
      payload: { center: { latitude, longitude } },
      meta: {},
    });
  };
};

export const updateArcData = makeActionCreator(ActionTypes.UPDATE_ARC_DATA, [
  'latitude',
  'longitude',
  'h3center',
  'data',
]);

export const deleteArcData = makeActionCreator(ActionTypes.DELETE_ARC_DATA, []);
