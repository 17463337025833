import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const StyledButton = styled(Button)`
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
    color: white;
    &:hover {
      background-color: #dd003f;
    }
    &:disabled {
      background-color: ${theme.palette.primary.A700};
    }
    width: 90px;
  `}
`;
