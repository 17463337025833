import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';

export const StyledFormFontrol = styled(FormControl)`
  ${({ theme }) => `
    margin-left: ${theme.spacing(3)}px;
    margin-right: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(2)}px;
    width: 330px;
  `}
`;
