import { ActionType } from '../types';
import ActionTypes from '../constants/actionTypes';
import { Reducer } from 'redux';
import { produce } from 'immer';

export type LoadingType = {
  isLoading: boolean;
};

export const initialState: LoadingType = {
  isLoading: false,
};

const loading: Reducer<LoadingType, ActionType> = produce(
  (draft = initialState, action) => {
    switch (action.type) {
      case ActionTypes.START_FETCH:
        draft['isLoading'] = true;
        return draft;
      case ActionTypes.END_FETCH:
        draft['isLoading'] = false;
        return draft;
      default:
        return draft;
    }
  }
);

export default loading;
