import styled from 'styled-components';

export const StyledAnchor = styled.a`
  font-size: 0.5rem;
  margin: 0px 3px;
`;

export const StyledLabel = styled.label`
  font-size: 0.5rem;
  margin: 0px 3px;
`;

export const StyledButtonDiv = styled.div`
  text-align: right;
  float: right;
  padding-right: 5px;
  padding-top: 5px;
`;