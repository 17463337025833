import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import ShareButtonList from '../components/molecules/ShareButtonList';
import { RootState } from '../store/configureStore';

const stateToProps = (state: RootState) => ({
  isLoading: state.loading.isLoading,
});
function dispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(stateToProps, dispatchToProps)(ShareButtonList);
