import { Reducer } from 'redux';
import { produce } from 'immer';
import ActionTypes from '../constants/actionTypes';
import { ActionType } from '../types';
import PeopleFlow from '../models/PeopleFlow';

export const initialState: PeopleFlow = new PeopleFlow();

const peopleFlow: Reducer<PeopleFlow, ActionType> = produce(
  (draft: PeopleFlow = initialState, action: ActionType) => {
    switch (action.type) {
      case ActionTypes.FETCH_PEOPLEFLOW:
        if (action.error) {
          return initialState;
        } else {
          return action.payload.data;
        }
    }
    return draft;
  }
);

export default peopleFlow;
