import { geoToH3, h3ToGeo, h3IsValid } from 'h3-js';
import { format } from 'date-fns';

const DEFAULT_LOCATION = String(process.env.REACT_APP_DEFAULT_LOCATION);
const HEXAGON_RESOLUTION = Number(process.env.REACT_APP_HEXAGON_RESOLUTION);

export const updateURL = (
  latitude: number,
  longitude: number,
  available: string
) => {
  const base_url = new URL(document.URL).origin + '/?';
  const h3Index = geoToH3(latitude, longitude, HEXAGON_RESOLUTION);
  const query = new URLSearchParams({
    date: format(new Date(available), 'yyyy-MM-dd'),
    h3Index: h3Index,
  });

  window.history.pushState(null, '', base_url + query.toString());
};

export const getParamLatLon = () => {
  const url = new URL(document.URL);
  const params = url.searchParams;
  const h3Index = params.has('h3Index') ? params.get('h3Index') : '';
  if (h3Index) {
    // データが不正の場合はデフォルト値を返却
    if (!h3IsValid(h3Index)) {
      return getDefaultLatLon();
    }
    return h3ToGeo(h3Index);
  }
  return getDefaultLatLon();
};

export const getParamH3Index = () => {
  const url = new URL(document.URL);
  const params = url.searchParams;
  const h3Index = params.has('h3Index') ? params.get('h3Index') : '';
  if (h3Index) {
    // データが不正の場合はデフォルト値を返却
    if (!h3IsValid(h3Index)) {
      return getDefaultH3Index();
    }
    return h3Index;
  }
  return getDefaultH3Index();
};

export const getParamDate = () => {
  const url = new URL(document.URL);
  const params = url.searchParams;
  const date = params.has('date') ? params.get('date') : '';
  const validDateFrom = new Date(String(process.env.REACT_APP_VALID_DATE_FROM));
  const validDateTo = new Date(String(process.env.REACT_APP_VALID_DATE_TO));
  if (date) {
    if (new Date(date).toString() === 'Invalid Date') {
      // 日付として不正なものは除く
      return validDateFrom;
    }
    if (
      // 有効期限外は除く
      !(validDateFrom <= new Date(date) && new Date(date) <= validDateTo)
    ) {
      return validDateFrom;
    }
    return date;
  }
  return validDateFrom;
};

const getDefaultLatLon = () => {
  const envLatLon = DEFAULT_LOCATION.split(',');
  if (envLatLon.length !== 2) {
    return [35.6753, 139.7625];
  } else {
    return [envLatLon[1], envLatLon[0]].map(Number);
  }
};

const getDefaultH3Index = () => {
  const defaultLatLon = getDefaultLatLon();
  return geoToH3(defaultLatLon[0], defaultLatLon[1], HEXAGON_RESOLUTION);
};
