import styled from 'styled-components';

export const StyledParagraph = styled.p`
  color: #fff;
  font-size: 50px;

  &::before {
    content: '';
    display: block;
    width: 5px;
    height: 30px;
    background-color: #fff;
    opacity: 0.8;
    position: absolute;
    right: 84px;
    top: 1px;
  }
  &::after {
    content: '';
    display: block;
    width: 5px;
    height: 30px;
    background-color: #fff;
    opacity: 0.8;
    position: absolute;
    right: 84px;
    bottom: 2px;
  }
`;
