import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import GridList from '@material-ui/core/GridList';

import TICXYPlot from '../atoms/TICXYPlot';
import ChartContent from './ChartContent';
import DetailHeader from '../atoms/DetailHeader';
import { StationCongestionLevelType, XYProtType } from '../../types';
import ActionTypes from '../../constants/actionTypes';

import {
  increaseColor,
  decreaseColor,
  defaultColor,
  ChipColor,
  ChipWidth,
} from './StationCongestion.styles';

import { XYPlot, ArcSeries, LabelSeries } from 'react-vis';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

type Props = {
  levelData: StationCongestionLevelType;
  timeData: Array<XYProtType>;
  ratioData: number | null;
  errors: Array<string>;
};

const getArcData = (level: number) => {
  return [
    {
      angle0: 0,
      angle: ((level * 5 - 1) * Math.PI) / 12,
      radius: 1.2,
      radius0: 0.8,
      color: '#00CC66',
    },
    {
      angle0: ((level * 5 - 1) * Math.PI) / 12,
      angle: 2 * Math.PI,
      radius: 1.2,
      radius0: 0.8,
      color: '#818181',
    },
  ];
};

const getArcLabel = (ratio: number | null) => {
  const fill = () => {
    if (ratio == null || ratio === 0) {
      return defaultColor;
    } else if (ratio > 0) {
      return increaseColor;
    } else {
      return decreaseColor;
    }
  };
  const arrow = () => {
    if (ratio == null || ratio === 0) {
      return '';
    } else if (ratio > 0) {
      return '↑';
    } else {
      return '↓';
    }
  };
  const label = ratio == null ? 'データなし' : `${Math.abs(ratio)}%`;

  return [
    {
      x: 2,
      y: 0,
      label: `前日比`,
      style: { fill: defaultColor, fontSize: 15 },
    },
    {
      x: ratio == null ? 5 : 0,
      y: -8,
      label: label,
      style: {
        fill: fill(),
        fontSize: ratio == null ? 14 : 21,
        fontWeight: ratio == null ? '' : 'bold',
      },
    },
    {
      x: 4,
      y: -8,
      label: arrow(),
      style: {
        fill: fill(),
        fontSize: 21,
        fontWeight: 'bold',
      },
    },
  ];
};

const StationCongestion: React.FunctionComponent<Props> = (props) => {
  const { levelData, timeData, ratioData, errors } = props;

  const isErrorLevel =
    errors.indexOf(ActionTypes.GET_STATION_CONGESTION_LEVEL) >= 0;
  const isErrorTime = errors.indexOf(ActionTypes.GET_PEOPLEFLOW_STAY) >= 0;
  const isErrorRatio =
    errors.indexOf(ActionTypes.GET_STATION_CONGESTION_RATIO) >= 0;

  return (
    <Card>
      <CardContent>
        <DetailHeader title={'駅周辺混雑度'} />
        <GridList cellHeight={224} cols={2}>
          <ChartContent
            title='混雑平均'
            key='sunburst-chart'
            error={isErrorLevel || isErrorRatio}
            helpText='選択した駅エリアの1日の混雜の平均（0:00〜24:00の平均）をLv1〜Lv5の5段階のレベルで示しています'
          >
            <XYPlot
              xDomain={[-15, 15]}
              yDomain={[-15, 15]}
              width={192}
              height={168}
            >
              <ArcSeries
                radiusDomain={[0, 1]}
                center={{ x: -3, y: -3 }}
                data={getArcData(levelData.level)}
                colorType={'literal'}
                style={{ stroke: '#818181' }}
              />
              <LabelSeries data={getArcLabel(ratioData)} />
            </XYPlot>
            <Box display='flex' alignItems='center' justifyContent='center'>
              {levelData.level !== -1 ? (
                <Chip
                  size='small'
                  label={`Lv${levelData.level}: ${levelData.label}`}
                  style={ChipColor}
                />
              ) : (
                <Chip size='small' label={``} style={ChipWidth} />
              )}
            </Box>
          </ChartContent>
          <ChartContent
            title='時間別'
            key='bar-chart'
            error={isErrorTime}
            helpText='選択した駅エリアの1時間毎の混雜の平均推移をLv1〜Lv5の5段階のレベルで示しています'
          >
            <TICXYPlot
              width={192}
              height={192}
              color={'#00CC66'}
              data={timeData}
              tickFormatX={(v) => `${v}時`}
              tickFormatY={(v) => `Lv${v}`}
              tickValuesX={[0, 6, 12, 18]}
              tickValuesY={[1, 2, 3, 4, 5]}
              yDomain={{ min: 0, max: 5 }}
            />
          </ChartContent>
        </GridList>
      </CardContent>
    </Card>
  );
};

export default StationCongestion;
