import React from 'react';
import { AlertType } from '../../types';
import { StyledDiv } from './AleartList.styles';
import { AlertWidget } from '../atoms/AlertWidget';

type Props = {
  alerts: Array<AlertType>;
  isLoading: boolean;
};

const AlertList: React.FC<Props> = (props) => {
  const { alerts, isLoading } = props;
  return alerts && alerts.length !== 0 && !isLoading ? (
    <StyledDiv>
      {alerts.map((alert, index) => (
        <AlertWidget key={index} alert={alert} />
      ))}
    </StyledDiv>
  ) : null;
};

export default AlertList;
