import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { geoToH3 } from 'h3-js';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { StyledDiv, StyledList } from './SimilarDateList.styles';
import { SimilarDateListPropsFromRedux } from '../../containers/SimilarDateListContainer';
import LoadingIcon from '../atoms/LoadingIcon';
import { OverlayScreenContent } from '../atoms/OverlayScreen';

const HEXAGON_RESOLUTION = Number(process.env.REACT_APP_HEXAGON_RESOLUTION);

const SimilarDateList: React.FC<SimilarDateListPropsFromRedux> = (props) => {
  const {
    fetcher,
    getSimilarDate,
    updateSelectSimilarDateIndex,
    closeFetcher,
    openLeftBar,
    closeLeftBar,
    updateFetcherDate,
    fetched,
    initAroundStations,
    updateCenter,
    deleteAlertAll,
    fetchPeopleFlowData,
    startLoading,
    endLoading,
    getPeopleFlowStay,
    getStationCongestionLevel,
    getStationCongestionRatio,
  } = props;
  const { selectIndex, data, error } = props.similarDate;

  const handleSimilarDateSubmit = async (dateTime: Date) => {
    const h3center = geoToH3(
      fetcher.latitude,
      fetcher.longitude,
      HEXAGON_RESOLUTION
    );
    const startDateTime = format(dateTime, "yyyy-MM-dd'T'00:00:00");
    const endDateTime = format(dateTime, "yyyy-MM-dd'T'23:59:59");

    closeFetcher();
    closeLeftBar();
    startLoading();
    initAroundStations();
    deleteAlertAll();
    updateCenter(fetcher.latitude, fetcher.longitude);
    updateFetcherDate(startDateTime, endDateTime);
    await fetchPeopleFlowData({
      latitude: fetcher.latitude,
      longitude: fetcher.longitude,
      format: 'h3',
      available: startDateTime,
      valid: endDateTime,
      h3center: null,
      defaultTime: {
        hours: dateTime.getHours(),
        minutes: dateTime.getMinutes(),
      },
    });
    await getPeopleFlowStay({
      h3center: h3center,
      available: startDateTime,
      valid: endDateTime,
    });
    await getStationCongestionLevel(h3center, {
      available: startDateTime,
      valid: endDateTime,
    });
    await getStationCongestionRatio(h3center, {
      available: startDateTime,
      valid: endDateTime,
    });
    fetched();
    endLoading();
    openLeftBar();
  };

  useEffect(() => {
    if (data && data.length === 0) {
      getSimilarDate();
    }
  }, [getSimilarDate, data]);

  return data && data.length !== 0 ? (
    <StyledDiv>
      <StyledList disablePadding={true}>
        {data.map((d, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem selected={index === selectIndex} disableGutters={true}>
                <ListItemText
                  primary={format(new Date(d.date), 'yyyy-MM-dd HH:mm')}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge='end'
                    aria-label='select'
                    onClick={() => {
                      updateSelectSimilarDateIndex(index);
                      handleSimilarDateSubmit(new Date(d.date));
                    }}
                  >
                    <OpenInNewIcon fontSize='small' />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
      </StyledList>
    </StyledDiv>
  ) : !error ? (
    <StyledDiv>
      <OverlayScreenContent justify='center' alignItems='center' height={250}>
        <LoadingIcon />
      </OverlayScreenContent>
    </StyledDiv>
  ) : (
    <StyledDiv>
      <Grid container direction='row' alignItems='flex-start'>
        <Grid container direction='row' alignItems='center' spacing={1}>
          <Grid item>
            <ErrorOutlineIcon color='primary' fontSize='small' />
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' component='h2' color='primary'>
              読み込みに失敗しました
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledDiv>
  );
};

export default SimilarDateList;
