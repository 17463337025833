import styled from 'styled-components';
import { OverlayScreenProps, OverlayScreenContentProps } from '.';

export const StyledScreen = styled.div.attrs((props: OverlayScreenProps) => ({
  color: props.color,
  width: props.width,
  height: props.height,
  opacity: props.opacity,
}))`
  position: absolute;
  width: ${(props) =>
    props.width === undefined ? '100%' : `${props.width}px`};
  height: ${(props) =>
    props.height === undefined ? '100%' : `${props.height}px`};
  overflow: hidden;
  background-color: ${(props) => props.color};
  opacity: ${(props) => (props.opacity === undefined ? '0.7' : props.opacity)};
`;

export const StyledScreenContent = styled.div.attrs(
  (props: OverlayScreenContentProps) => ({
    justify: props.justify,
    alignItems: props.alignItems,
    height: props.height,
  })
)`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justify === undefined ? 'flex-start' : props.justify};
  align-items: ${(props) =>
    props.alignItems === undefined ? 'stretch' : props.alignItems};
  height: ${(props) =>
    props.height === undefined ? '100%' : `${props.height}px`};
`;
