export const centerHexLineColor = [228, 0, 79];
export const aroundHexLineColor = [0, 0, 0];
export const hexFillColors = [
  [255, 255, 255], //value = 0
  [255, 245, 240], //1
  [254, 224, 210], //2
  [252, 187, 161], //3
  [252, 146, 114], //4
  [251, 106, 74], //5
  [239, 59, 44], //6
  [203, 24, 29], //7
  [165, 15, 21], //8
  [103, 0, 13], //9
  [62, 0, 0], //10
];
export const arcLineColors = [
  [255, 128, 150], //value = 0
  [255, 102, 133], //1
  [255, 77, 117], //2
  [255, 51, 103], //3
  [251, 26, 90], //4
  [228, 0, 79], //5
  [195, 10, 74], //6
  [164, 18, 69], //7
  [136, 24, 63], //8
  [109, 27, 56], //9
  [86, 29, 48], //10
];
