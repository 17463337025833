import React from 'react';

import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Button, { ButtonProps } from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export type ToggleButtonProps = {
  open: boolean;
};

export const HorizontalToggleButton = <C extends React.ElementType>(
  props: IconButtonProps<C, { component?: C }> & ToggleButtonProps
) => {
  const { open } = props;

  return (
    <IconButton {...props}>
      {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );
};

export const VerticalToggleButton = <C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }> & ToggleButtonProps
) => {
  const { open } = props;
  const icon = open ? <ExpandLess /> : <ExpandMore />;

  return <Button {...props} startIcon={icon}></Button>;
};
