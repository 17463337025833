/// app.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import DeckGL from '@deck.gl/react';
import { StaticMap } from 'react-map-gl';
import { H3HexagonLayer, Tile3DLayer } from '@deck.gl/geo-layers';
import { geoToH3 } from 'h3-js';
import { format } from 'date-fns';
import { Tiles3DLoader } from '@loaders.gl/3d-tiles';

import { H3HexagonType, ArcType } from '../../types';
import { MapPropsFromRedux } from '../../containers/MapContainer';
import { LoadingScreen } from '../molecules/LoadingScreen';
import { MapOption } from '../molecules/MapOption';
import { h3HexagonLayerProps } from '../../Layers/H3HexagonLayer';
import { arcLayerProps } from '../../Layers/ArcLayer';
import AnimatedArcLayer from '../../Layers/AnimatedArcLayer';
import { tile3DLayerProps } from '../../Layers/Tile3DLayer';
import { getParamLatLon, getParamDate } from '../../lib/URL';
import { filterStationList } from '../../lib/Array';
import {
  centerHexLineColor,
  aroundHexLineColor,
  hexFillColors,
  arcLineColors,
} from '../../lib/Color';
import { mapStyle } from './Map.styles';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const MAPBOX_STYLE = process.env.REACT_APP_MAPBOX_STYLE;
const HEXAGON_RESOLUTION = Number(process.env.REACT_APP_HEXAGON_RESOLUTION);
const INITIAL_ZOOM_LEVEL = 13;

type Props = MapPropsFromRedux;

//地図表示エリア
const mapBounds = {
  min_lat: 34.56,
  max_lat: 36.52,
  min_lon: 138.69,
  max_lon: 140.88,
};

const Map: React.FC<Props> = (props) => {
  const { center, layerData } = props.map;
  const { isLoading } = props.loading;
  const {
    fetcher,
    peopleFlow,
    currentTime,
    fetchPeopleFlowData,
    updateCenter,
    startLoading,
    endLoading,
    openLeftBar,
    closeLeftBar,
    initAroundStations,
    deleteAlertAll,
    setFetcherStation,
    getPeopleFlowStay,
    getStationCongestionLevel,
    getStationCongestionRatio,
    updateSelectSimilarDateIndex,
    fetched,
  } = props;

  const [visibleLayer, setVisibleLayer] = useState({
    arc: true,
    tile3d: false,
  });
  const mapRef = useRef<StaticMap>(null);

  const initialViewState = {
    longitude: center.longitude,
    latitude: center.latitude,
    zoom: INITIAL_ZOOM_LEVEL,
    maxZoom: 20,
    minZoom: 10,
    pitch: 40,
    bearing: 0,
  };

  const doFetch = useCallback(
    async (
      latitute: number,
      longitude: number,
      startDateTime: string,
      endDateTime: string
    ) => {
      closeLeftBar();
      deleteAlertAll();
      startLoading();
      initAroundStations();
      updateCenter(latitute, longitude);
      await fetchPeopleFlowData({
        latitude: latitute,
        longitude: longitude,
        format: 'h3',
        available: startDateTime,
        valid: endDateTime,
        h3center: null,
        defaultTime: { hours: 9 },
      });
      const newH3Index = geoToH3(latitute, longitude, HEXAGON_RESOLUTION);
      const stationData = filterStationList(newH3Index, fetcher.stationList);
      if (0 < stationData.length) {
        updateSelectSimilarDateIndex(-1);
        setFetcherStation(
          newH3Index,
          stationData[0].id,
          stationData[0].geometry.coordinates[1],
          stationData[0].geometry.coordinates[0]
        );
        await getPeopleFlowStay({
          h3center: newH3Index,
          available: startDateTime,
          valid: endDateTime,
        });
        await getStationCongestionLevel(newH3Index, {
          available: startDateTime,
          valid: endDateTime,
        });
        await getStationCongestionRatio(newH3Index, {
          available: startDateTime,
          valid: endDateTime,
        });
        fetched();
        openLeftBar();
      }
      endLoading();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getExtensionTooltip = (title: string, value: number, color: string) => {
    return `<div>
        <table style="border-spacing: 0px;">
          <tr style="height: 15px; line-height: 10px;">
            <td style="padding-right: 10px;">${title}</td>
            <td style="width: ${
              (value / 10) * 100
            }px; background-color: ${color}; opacity: 0.7;"></td>
            <td style="width: ${
              ((10 - value) / 10) * 100
            }px; background-color: ${color}; opacity: 0.3;"></td>
          </tr>
        </table>
      </div>`;
  };

  useEffect(() => {
    const latlon = getParamLatLon();
    const date = getParamDate();
    const startDateTime = format(new Date(date), "yyyy-MM-dd'T'00:00:00");
    const endDateTime = format(new Date(date), "yyyy-MM-dd'T'23:59:59");
    doFetch(latlon[0], latlon[1], startDateTime, endDateTime);
  }, [doFetch]);

  const getInTime = useCallback(
    (timestamp: number): { timeDiff: number; isInTime: boolean } => {
      const range = 900;
      const timeDiff = timestamp - currentTime;
      const abs = Math.abs(timeDiff);
      const isInTime = 0 <= timeDiff && timeDiff < range;
      return { timeDiff: abs, isInTime: isInTime };
    },
    [currentTime]
  );

  const layers = layerData.map((layer) => {
    switch (layer.type) {
      case 'h3-hexagon-layer':
        return new H3HexagonLayer({
          ...h3HexagonLayerProps,
          data: peopleFlow.data,
          getFillColor: (d: H3HexagonType) => {
            const { value, hexagon_id, timestamp } = d;
            const { isInTime } = getInTime(timestamp);
            const h3Index = geoToH3(
              center.latitude,
              center.longitude,
              HEXAGON_RESOLUTION
            );
            const opacity = isInTime ? 150 : 0;
            if (hexagon_id === h3Index) {
              return [...hexFillColors[value], opacity];
            } else {
              return [...hexFillColors[value], opacity];
            }
          },
          getLineColor: (d: H3HexagonType) => {
            const { hexagon_id, timestamp } = d;
            const { isInTime } = getInTime(timestamp);
            const opacity = isInTime ? 255 : 0;
            const h3Index = geoToH3(
              center.latitude,
              center.longitude,
              HEXAGON_RESOLUTION
            );
            if (hexagon_id === h3Index) {
              return [...centerHexLineColor, opacity];
            } else {
              return [...aroundHexLineColor, 0];
            }
          },
          updateTriggers: {
            getFillColor: currentTime,
            getLineColor: currentTime,
          },
        });

      case 'arc-layer':
        // @ts-ignore
        return new AnimatedArcLayer({
          ...arcLayerProps,
          data: layer.data,
          getSourcePosition: (d: ArcType) => d.from.coordinates,
          getTargetPosition: (d: ArcType) => d.to.coordinates,
          getSourceColor: (d: ArcType) => {
            return [...arcLineColors[1], 180];
          },
          getTargetColor: (d: ArcType) => {
            return [...arcLineColors[1], 180];
          },
          getWidth: (d: ArcType) => {
            const { inbound, timestamp } = d;
            const { isInTime } = getInTime(timestamp);
            const width = isInTime ? inbound * 3 : 0;
            return width;
          },
          getTailLength: (d: ArcType) => 0.005,
          updateTriggers: {
            getWidth: currentTime,
          },
          visible: visibleLayer.arc,
        });

      case 'tile-3D-layer':
        if (!visibleLayer.tile3d) return null;
        return new Tile3DLayer({
          ...tile3DLayerProps,
          data:
            'https://s3-ap-northeast-1.amazonaws.com/3dimension.jp/13000_tokyo-egm96/13101_chiyoda-ku/tileset.json',
          loader: Tiles3DLoader,
          loadOptions: {
            '3d-tiles': { isTileset: true },
            isTileset: false,
          },
          _subLayerProps: {
            scenegraph: { _lighting: 'pbr' },
          },
        });
      default:
        return null;
    }
  });

  const onViewStateChange = useCallback(({ viewState }) => {
    // layerのmaxBounds制御
    if (viewState.latitude < mapBounds.min_lat + 0.25) {
      viewState.latitude = mapBounds.min_lat + 0.25;
    }
    if (viewState.latitude > mapBounds.max_lat - 0.25) {
      viewState.latitude = mapBounds.max_lat - 0.25;
    }
    if (viewState.longitude < mapBounds.min_lon + 0.5) {
      viewState.longitude = mapBounds.min_lon + 0.5;
    }
    if (viewState.longitude > mapBounds.max_lon - 0.5) {
      viewState.longitude = mapBounds.max_lon - 0.5;
    }
    return viewState;
  }, []);

  const onVisibleLayerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibleLayer({
      ...visibleLayer,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <React.Fragment>
      <LoadingScreen isLoading={isLoading} />
      <MapOption
        isLoading={isLoading}
        visibleLayer={visibleLayer}
        onChange={onVisibleLayerChange}
      />
      <DeckGL
        style={mapStyle}
        initialViewState={initialViewState}
        controller={true}
        layers={layers}
        getTooltip={({ object }) => {
          if (object === undefined) return;
          if ('value' in object) {
            const { value, hexagon_id } = object;
            const h3Index = geoToH3(
              center.latitude,
              center.longitude,
              HEXAGON_RESOLUTION
            );
            let title = '移動量';
            if (h3Index === hexagon_id) {
              title = '滞在量';
            }
            return (
              object && {
                html: getExtensionTooltip(title, value, '#e4004f'),
              }
            );
          } else if ('title' in object) {
            return object && `${object.title}駅`;
          }
        }}
        onViewStateChange={onViewStateChange}
        onClick={(info) => {
          const { startDateTime, endDateTime } = props.fetcher;
          doFetch(
            info.coordinate[1],
            info.coordinate[0],
            startDateTime,
            endDateTime
          );
        }}
      >
        <StaticMap
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
          mapStyle={MAPBOX_STYLE}
          ref={mapRef}
          attributionControl={false}
          mapOptions={{
            maxBounds: [
              [mapBounds.min_lon, mapBounds.min_lat],
              [mapBounds.max_lon, mapBounds.max_lat],
            ],
          }}
        />
      </DeckGL>
    </React.Fragment>
  );
};

export default Map;
