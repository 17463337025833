import React from 'react';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import OverlayScreen from '../atoms/OverlayScreen';
import { ErrorTooltip, HelpTooltip } from '../atoms/CustomizedTooltip';

type Props = {
  error: boolean;
  title?: string;
  helpText?: string;
};

const ChartContent: React.FunctionComponent<Props> = (props) => {
  const { error, title, helpText, children } = props;
  return (
    <GridListTile cols={1}>
      {title ? (
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
        >
          <Grid
            container
            item
            direction='row'
            alignItems='center'
            spacing={1}
            xs={10}
          >
            <Grid item>
              <Typography variant='h6' component='h2'>
                {title}
              </Typography>
            </Grid>
            {error ? (
              <Grid item xs={2}>
                <ErrorTooltip
                  title='読み込みに失敗しました'
                  placement='bottom-start'
                >
                  <ErrorOutlineIcon color='secondary' fontSize='inherit' />
                </ErrorTooltip>
              </Grid>
            ) : null}
          </Grid>
          {helpText ? (
            <Grid container item direction='row' justify='center' xs={2}>
              <HelpTooltip title={helpText} placement='bottom-start'>
                <HelpOutlineIcon color='secondary' fontSize='inherit' />
              </HelpTooltip>
            </Grid>
          ) : null}
        </Grid>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {error ? <OverlayScreen color='gray' width={190} height={192} /> : null}
      {children}
    </GridListTile>
  );
};

export default ChartContent;
