import { immerable, produce } from 'immer';
import {
  CongestionType,
  PeopleFlowStayType,
  StationCongestionLevelType,
  StatoinCongestionRatioType,
  XYProtType,
} from '../types';
class Congestion {
  [immerable] = true;
  data: CongestionType;
  peopleFlowStay: Array<XYProtType>;
  stationCongestionLevel: StationCongestionLevelType;
  statoinCongestionRatio: number | null;
  errors: Array<string>;
  constructor() {
    this.data = {
      peopleFlowStay: {
        available: '',
        valid: '',
        average_stay_count_per_1_hours: [],
      },
      stationCongestionLevel: {
        level: -1,
        label: '',
      },
      statoinCongestionRatio: {
        id: '',
        available: '',
        valid: '',
        congestion_ratio: [],
      },
    };
    this.peopleFlowStay = this.emptyPeopleFlowStayData;
    this.stationCongestionLevel = {
      level: -1,
      label: '',
    };
    this.statoinCongestionRatio = 0;
    this.errors = [];
  }

  emptyPeopleFlowStayData = [...Array(24)].map((_, index) => {
    return { id: index.toString(), y: 0, x: index };
  });

  setPeopleFlowStayEmptyData() {
    return produce(this, (draft: Congestion) => {
      draft.peopleFlowStay = this.emptyPeopleFlowStayData;
    });
  }

  setStationCongestionLevelEmptyData() {
    return produce(this, (draft: Congestion) => {
      draft.stationCongestionLevel = {
        level: -1,
        label: '',
      };
    });
  }

  setStationCongestionRatioEmptyData() {
    return produce(this, (draft: Congestion) => {
      draft.statoinCongestionRatio = null;
    });
  }

  updatePeopleFlowStay(data: PeopleFlowStayType) {
    return produce(this, (draft: Congestion) => {
      const peopleFlowStayData = data.average_stay_count_per_1_hours.map(
        (data, index) => {
          return { id: index.toString(), y: data, x: index };
        }
      );
      draft.peopleFlowStay = peopleFlowStayData;
    });
  }
  updateStationCongestionLevel(data: StationCongestionLevelType) {
    return produce(this, (draft: Congestion) => {
      draft.stationCongestionLevel = data;
    });
  }
  updateStationCongestionRatio(data: StatoinCongestionRatioType) {
    return produce(this, (draft: Congestion) => {
      draft.statoinCongestionRatio = this.getCongestionRatio(
        data.congestion_ratio
      );
    });
  }

  getCongestionRatio(ratios: Array<number>): number | null {
    if (ratios.length > 0) {
      const total = ratios.reduce((sum, ratio) => {
        if (ratio >= 1) {
          return sum + (ratio - 1);
        } else {
          return sum - ratio;
        }
      }, 0);
      const average = total / ratios.length;
      return Math.ceil(average * 100);
    } else {
      return null;
    }
  }

  addError(type: string) {
    return produce(this, (draft: Congestion) => {
      draft.errors.push(type);
    });
  }

  deleteAllError() {
    return produce(this, (draft: Congestion) => {
      draft.errors = [];
    });
  }
}

export default Congestion;
