import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { StyledFormFontrol } from './index.styles';
import FormHelperText from '@material-ui/core/FormHelperText';

export type FetcherAutoCompleteProps = {
  id: string;
  options: Array<any>;
  label: string;
  error: boolean;
  errorText: string | undefined;
  value: string;
  onChange: (e: any, v: any) => void;
};

const FetcherAutoComplete: React.FC<FetcherAutoCompleteProps> = (props) => {
  const { id, label, error, errorText, options, value, onChange } = props;

  const defaultProps = {
    options: options,
    getOptionLabel: (option) => option.title,
  };

  const setValue = options.filter((item) => {
    if (item.id === value) return true;
    else return false;
  });

  return (
    <StyledFormFontrol>
      <Autocomplete
        {...defaultProps}
        id={id}
        value={setValue.length !== 0 ? setValue[0] : null}
        renderInput={(params) => (
          <TextField {...params} label={label} margin='normal' />
        )}
        onChange={onChange}
      />
      {error ? <FormHelperText error>{errorText}</FormHelperText> : null}
    </StyledFormFontrol>
  );
};

export default FetcherAutoComplete;
