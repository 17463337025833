/// app.js
import React from 'react';

import MapContainer from './containers/MapContainer';
import Header from './components/organisms/Header';
import AlertsContainer from './containers/AlertsContainer';
import KPIListsContainer from './containers/KPIListsContainer';
import TimeSliderContainer from './containers/TimeSliderContainer';
import FooterContainer from './containers/FooterContainer';
import { Cookie } from './components/atoms/Cookie';
import { ThemeProvider } from 'styled-components';
import { theme } from './App.styles';
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './lib/AppInsights';

// Styles definition
const App = () => {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <MapContainer />
          <Header />
          <AlertsContainer />
          <KPIListsContainer />
          <TimeSliderContainer />
          <FooterContainer />
          <Cookie />
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default withAITracking(reactPlugin, App);
