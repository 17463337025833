import { connect, ConnectedProps } from 'react-redux';
import SimilarDateList from '../components/molecules/SimilarDateList';
import { RootState } from '../store/configureStore';
import {
  getSimilarDate,
  updateSelectSimilarDate,
  updateSelectSimilarDateIndex,
} from '../actions/similarDate';
import {
  fetchPeopleFlowData,
  updateFetcherDate,
  closeFetcher,
  fetched,
} from '../actions/fetcher';
import { openLeftBar, closeLeftBar } from '../actions/leftBar';
import { updateCenter } from '../actions/map';
import { startLoading, endLoading } from '../actions/loading';
import { deleteAlertAll } from '../actions/alerts';
import { initAroundStations } from '../actions/aroundStations';
import {
  getPeopleFlowStay,
  getStationCongestionLevel,
  getStationCongestionRatio,
} from '../actions/congestion';

const state = (state: RootState) => ({
  fetcher: state.fetcher,
  similarDate: state.similarDate,
});

const dispatch = {
  getSimilarDate,
  updateSelectSimilarDate,
  updateSelectSimilarDateIndex,
  updateFetcherDate,
  closeFetcher,
  fetched,
  openLeftBar,
  closeLeftBar,
  initAroundStations,
  updateCenter,
  deleteAlertAll,
  fetchPeopleFlowData,
  startLoading,
  endLoading,
  getPeopleFlowStay,
  getStationCongestionLevel,
  getStationCongestionRatio,
};

const connector = connect(state, dispatch);

export type SimilarDateListPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SimilarDateList);
