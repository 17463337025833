import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

export const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  text-align: center;
  font-size: 1rem;
`;
