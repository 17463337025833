import React, { useState, useEffect } from 'react';
import fromUnixTime from 'date-fns/fromUnixTime';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

import { TimeType } from '../../types';
import { SliderBlock } from '../molecules/SliderBlock';
import TimeDisplay from '../atoms/TimeDisplay';
import { theme, StyledDiv } from './TimeSlider.styles';
import { TimeSliderProps } from '../../containers/TimeSliderContainer';

//@todo AnimationControlWidgetにリネーム
const TimeSlider: React.FC<TimeSliderProps> = (props) => {
  const {
    peopleFlow,
    valueFrom,
    valueTo,
    currentTime,
    updateCurrentTime,
    isOpenLeftBar,
  } = props;
  const { isLoading } = props.loading;
  const [defaultTime, setDefaultTime] = useState<TimeType>({ hours: 0 });

  useEffect(() => {
    const hours = fromUnixTime(currentTime).getHours();
    const minutes = fromUnixTime(currentTime).getMinutes();
    setDefaultTime({ hours, minutes });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFrom, valueTo]);

  return peopleFlow && peopleFlow.data.length !== 0 && !isLoading ? (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StyledDiv open={isOpenLeftBar}>
          <TimeDisplay
            timeStamp={
              currentTime === 0
                ? fromUnixTime(valueFrom)
                : fromUnixTime(currentTime)
            }
          />
          <SliderBlock
            onChangeSlider={updateCurrentTime}
            value={currentTime}
            valueFrom={valueFrom}
            valueTo={valueTo}
            defaultTime={defaultTime}
          />
        </StyledDiv>
      </ThemeProvider>
    </MuiThemeProvider>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default TimeSlider;
