import { connect, ConnectedProps } from 'react-redux';
import Map from '../components/organisms/Map';
import {
  fetchPeopleFlowData,
  setFetcherStation,
  fetched,
} from '../actions/fetcher';
import { updateCenter } from '../actions/map';
import { startLoading, endLoading } from '../actions/loading';
import { openLeftBar, closeLeftBar } from '../actions/leftBar';
import { initAroundStations } from '../actions/aroundStations';
import { deleteAlertAll } from '../actions/alerts';
import {
  getPeopleFlowStay,
  getStationCongestionLevel,
  getStationCongestionRatio,
} from '../actions/congestion';
import { updateSelectSimilarDateIndex } from '../actions/similarDate';

import { RootState } from '../store/configureStore';

const mapState = (state: RootState) => ({
  map: state.map,
  peopleFlow: state.peopleFlow,
  currentTime: state.timeSlider.currentTime,
  fetcher: state.fetcher,
  loading: state.loading,
});

const mapDispatch = {
  fetchPeopleFlowData,
  updateCenter,
  startLoading,
  endLoading,
  openLeftBar,
  closeLeftBar,
  initAroundStations,
  deleteAlertAll,
  setFetcherStation,
  getPeopleFlowStay,
  getStationCongestionLevel,
  getStationCongestionRatio,
  fetched,
  updateSelectSimilarDateIndex,
};

const connector = connect(mapState, mapDispatch);

export type MapPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Map);
