import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      A700: '#de6f8e',
      main: '#e4004f',
    },
    secondary: {
      main: '#9e9e9e',
    },
  },
});

export const StyledGrid = styled(Grid)`
  position: absolute;
  pointer-events: none;
`;

const getHeight = window.innerHeight <= 768 ? 550 : 768;

export const StyledGridKPIList = styled(Grid)`
  pointer-events: initial;
  height: ${getHeight}px;
  overflow-x: hidden;
`;

export const StyledImg = styled.img`
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 300px;
`;
