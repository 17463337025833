import { ActionType } from '../types';
import ActionTypes from '../constants/actionTypes';
import { produce } from 'immer';
import { Reducer } from 'redux';
import * as d3 from 'd3';

export type SimilarDateType = {
  index: number;
  date: Date;
  rate: number;
  railway_title: string;
};

export type SimilarDateListType = {
  selectIndex: number;
  data: Array<SimilarDateType>;
  selectDate: Date;
  error: boolean;
};

export const initialState: SimilarDateListType = {
  selectDate: new Date('2020-04-01T00:00:00'),
  selectIndex: -1,
  data: [],
  error: false,
};

const removeDuplicatesFromArrayByProperty = (arr, prop) =>
  arr.reduce((accumulator, currentValue) => {
    if (!accumulator.find((obj) => obj[prop] === currentValue[prop])) {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);

const similarDate: Reducer<SimilarDateListType, ActionType> = produce(
  (draft = initialState, action: ActionType) => {
    switch (action.type) {
      case ActionTypes.GET_SIMILAR_DATE: {
        if (action.error) {
          return { ...initialState, error: true };
        } else {
          const { data } = action.payload;
          const deleteDuplicateData = removeDuplicatesFromArrayByProperty(
            data,
            'date'
          );
          const sortedData = deleteDuplicateData.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return d3.ascending(+dateA, +dateB);
          });
          return { ...draft, data: sortedData, error: false };
        }
      }
      case ActionTypes.UPDATE_SELECT_DATE: {
        const { newDate } = action.payload;
        draft['selectDate'] = newDate;
        return draft;
      }
      case ActionTypes.UPDATE_SELECT_INDEX: {
        const { newIndex } = action.payload;
        draft['selectIndex'] = newIndex;
        return draft;
      }
      default:
        return draft;
    }
  }
);

export default similarDate;
