import React, { useState } from 'react';
import { AlertType } from '../../../types';
import Collapse from '@material-ui/core/Collapse';
import { StyledAlert } from './index.styles';

export type AlertWidgetProps = {
  alert: AlertType;
};

export const AlertWidget: React.FC<AlertWidgetProps> = (props) => {
  const { alert } = props;
  const [open, setOpen] = useState(true);

  return (
    <Collapse in={open}>
      <StyledAlert
        severity={alert.type}
        onClose={() => {
          setOpen(false);
        }}
      >
        {alert.message}
      </StyledAlert>
    </Collapse>
  );
};
