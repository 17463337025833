import { ActionType } from '../types';
import ActionTypes from '../constants/actionTypes';
import { Reducer } from 'redux';
import { produce } from 'immer';
import stations from '../assets/json/stations.json';
import { h3ToGeo, geoToH3 } from 'h3-js';
import { ArcType } from '../types';

type LayerData = {
  type: string;
  url?: string;
  data?: any;
};

export type MapStateType = {
  layerData: Array<LayerData>;
  center: {
    longitude: number;
    latitude: number;
  };
};

const defaultLngLat:
  | Array<string>
  | undefined = process.env.REACT_APP_DEFAULT_LOCATION?.split(',');

export const initialState: MapStateType = {
  layerData: [
    {
      type: 'icons-layer',
      data: stations,
    },
    {
      type: 'h3-hexagon-layer',
    },
    {
      type: 'arc-layer',
      data: [],
    },
    {
      type: 'tile-3D-layer',
    },
  ],
  center: {
    longitude:
      defaultLngLat?.length === 2 ? Number(defaultLngLat[0]) : 139.7625,
    latitude: defaultLngLat?.length === 2 ? Number(defaultLngLat[1]) : 35.6753,
  },
};

const map: Reducer<MapStateType, ActionType> = produce(
  (draft = initialState, action) => {
    switch (action.type) {
      case ActionTypes.UPDATE_CENTER: {
        const { center } = action.payload;
        draft['center'] = center;
        return draft;
      }
      case ActionTypes.UPDATE_ARC_DATA: {
        if (action.error) {
          return draft;
        } else {
          const { data, latitude, longitude, h3center } = action.payload;
          let [lngTo, latTo] = [0, 0];
          if (h3center != null) {
            [latTo, lngTo] = h3ToGeo(h3center);
          } else {
            //地図クリック地点と駅の緯度経度の場合、ヘキサゴンの中心点からずれるため変換
            const h3Index = geoToH3(
              latitude,
              longitude,
              Number(process.env.REACT_APP_HEXAGON_RESOLUTION)
            );
            [latTo, lngTo] = h3ToGeo(h3Index);
          }
          const arcData: ArcType = data.map((d) => {
            const [lat, lng] = h3ToGeo(d.hexagon_id);

            return {
              inbound: d.value,
              outbound: d.value,
              from: {
                name: '',
                coordinates: [lng, lat],
              },
              to: {
                name: '',
                coordinates: [lngTo, latTo],
              },
              timestamp: d.timestamp,
            };
          });
          draft['layerData'] = draft['layerData'].map((d: LayerData) => {
            if (d.type === 'arc-layer') {
              d.data = arcData;
            }
            return d;
          });
          return draft;
        }
      }
      case ActionTypes.DELETE_ARC_DATA:
        draft['layerData'] = draft['layerData'].map((d: LayerData) => {
          if (d.type === 'arc-layer') {
            d.data = [];
          }
          return d;
        });
        return draft;
      default:
        return draft;
    }
  }
);

export default map;
