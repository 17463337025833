import React from 'react';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { ErrorTheme, HtmlTheme } from './index.styles';

type HelpTooltipProps = TooltipProps & { content?: React.ReactNode };

export const ErrorTooltip: React.FC<TooltipProps> = (props) => {
  const { children } = props;
  return (
    <MuiThemeProvider theme={ErrorTheme}>
      <ThemeProvider theme={ErrorTheme}>
        <Tooltip {...props}>{children}</Tooltip>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export const BasicTooltip: React.FC<TooltipProps> = (props) => {
  const { children } = props;
  return <Tooltip {...props}>{children}</Tooltip>;
};

export const HelpTooltip: React.FC<HelpTooltipProps> = (props) => {
  const { title, content, children } = props;
  return (
    <MuiThemeProvider theme={HtmlTheme}>
      <ThemeProvider theme={HtmlTheme}>
        <Tooltip
          {...props}
          title={
            <React.Fragment>
              <Typography color='inherit'>{title}</Typography>
              {content}
            </React.Fragment>
          }
        >
          {children}
        </Tooltip>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
