import React from 'react';
import { StyledTextField } from './index.styles';

export type DatePickerProps = {
  id: string;
  name: string;
  label: string;
  value: string;
  min: string;
  max: string;
  onChange: (e: any) => void;
  error: boolean;
  errorText: string | undefined;
};

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    id,
    name,
    label,
    value,
    min,
    max,
    onChange,
    error,
    errorText,
  } = props;

  return (
    <StyledTextField
      id={id}
      name={name}
      label={label}
      type='date'
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{ inputProps: { min, max } }}
      value={value}
      onChange={onChange}
      error={error}
      helperText={errorText}
    />
  );
};

export default DatePicker;
