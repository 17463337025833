import { immerable, produce } from 'immer';
import { H3HexagonType } from '../types';

class PeopleFlow {
  [immerable] = true;
  data: Array<H3HexagonType>;
  date: {
    from: number;
    to: number;
  };
  constructor() {
    this.date = {
      from: 0,
      to: 0,
    };
    this.data = [];
  }

  updateData(data: Array<H3HexagonType>) {
    return produce(this, (draft: PeopleFlow) => {
      draft.data = data;
      let minTime = data[0].timestamp;
      let maxTime = data[0].timestamp;
      data.forEach((h3) => {
        minTime = h3.timestamp < minTime ? h3.timestamp : minTime;
        maxTime = h3.timestamp > maxTime ? h3.timestamp : maxTime;
      });
      draft.date = { from: minTime, to: maxTime };
    });
  }

  getFromTime(data: Array<H3HexagonType>) {
    let minTime = data[0].timestamp;
    data.forEach((h3) => {
      minTime = h3.timestamp < minTime ? h3.timestamp : minTime;
    });
    return minTime;
  }
}

export default PeopleFlow;
