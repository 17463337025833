import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { format } from 'date-fns';
import vest from 'vest';
import { geoToH3 } from 'h3-js';
import FetcherAutoComplete from '../atoms/FetcherAutoComplete';
import DatePicker from '../atoms/DatePicker';
import FetcherButton from '../atoms/FetcherButton';
import FetcherContentTitle from '../molecules/FetcherContentTitle';
import SimilarDateListContainer from '../../containers/SimilarDateListContainer';
import { StyledButton } from './Fetcher.styles';
import validate from './Fetcher.validate';
import { FetcherPropsFromRedux } from '../../containers/FetcherContainer';
import { getParamDate, getParamH3Index } from '../../lib/URL';
import { filterStationList } from '../../lib/Array';

type Props = FetcherPropsFromRedux;

const hex_resolution: number = Number(process.env.REACT_APP_HEXAGON_RESOLUTION);

const Fetcher: React.FC<Props> = (props) => {
  const {
    fetcher,
    fetchPeopleFlowData,
    openLeftBar,
    closeLeftBar,
    getPeopleFlowStay,
    getStationCongestionLevel,
    getStationCongestionRatio,
    setFetcherStation,
    updateFetcherStation,
    updateFetcherDate,
    openFetcher,
    closeFetcher,
    fetched,
    updateCenter,
    startLoading,
    endLoading,
    deleteAlertAll,
    deleteCongestionErrorAll,
    updateSelectSimilarDateIndex,
  } = props;
  const { isLoading } = props.loading;
  const [errors, setErrors] = useState(() => vest.get('fetcher'));

  const handleSubmit = () => {
    runValidate().done(async (output) => {
      const invalid = output.hasErrors();
      if (!invalid) {
        const h3center = geoToH3(
          fetcher.latitude,
          fetcher.longitude,
          hex_resolution
        );
        closeFetcher();
        closeLeftBar();
        startLoading();
        updateCenter(fetcher.latitude, fetcher.longitude);
        deleteAlertAll();
        deleteCongestionErrorAll();
        updateSelectSimilarDateIndex(-1);
        await fetchPeopleFlowData({
          latitude: fetcher.latitude,
          longitude: fetcher.longitude,
          format: 'h3',
          available: fetcher.startDateTime,
          valid: fetcher.endDateTime,
          h3center: null,
          defaultTime: { hours: 9 },
        });
        await getPeopleFlowStay({
          h3center: h3center,
          available: fetcher.startDateTime,
          valid: fetcher.endDateTime,
        });
        await getStationCongestionLevel(h3center, {
          available: fetcher.startDateTime,
          valid: fetcher.endDateTime,
        });
        await getStationCongestionRatio(h3center, {
          available: fetcher.startDateTime,
          valid: fetcher.endDateTime,
        });
        fetched();
        endLoading();
        openLeftBar();
      }
    });
  };

  const runValidate = (name?: string, value?: any) => {
    const res = validate(
      { ...fetcher, ...(name && { [name]: value }) },
      { currentField: name }
    );
    setErrors(res);
    return res;
  };

  const handleChangeDates = (e: any) => {
    const {
      target: { value, name },
    } = e;

    if (value === '') {
      return;
    }

    const startDateTime = format(new Date(value), "yyyy-MM-dd'T'00:00:00");
    const endDateTime = format(new Date(value), "yyyy-MM-dd'T'23:59:59");

    updateFetcherDate(startDateTime, endDateTime);
    runValidate(name, value);
  };

  const handleChangeStation = (e: any, v: any) => {
    const id = v === null ? '' : v.id;
    const geometry = v === null ? '' : v.geometry;
    const lonlat = geometry.length !== 0 ? geometry.coordinates : [0, 0];

    updateFetcherStation(id, lonlat[1], lonlat[0]);
    runValidate('station', id);
  };

  useEffect(() => {
    const date = getParamDate();
    const h3Index = getParamH3Index();
    if (date) {
      const startDateTime = format(new Date(date), "yyyy-MM-dd'T'00:00:00");
      const endDateTime = format(new Date(date), "yyyy-MM-dd'T'23:59:59");
      updateFetcherDate(startDateTime, endDateTime);
    }
    if (h3Index) {
      const stationData = filterStationList(h3Index, fetcher.stationList);
      if (0 < stationData.length) {
        setFetcherStation(
          h3Index,
          stationData[0].id,
          stationData[0].geometry.coordinates[1],
          stationData[0].geometry.coordinates[0]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <StyledButton
        onClick={() => {
          openFetcher();
        }}
        disabled={isLoading}
      >
        データ絞込
      </StyledButton>

      <Drawer
        anchor='right'
        open={fetcher.isOpen}
        onClose={() => {
          closeFetcher();
        }}
      >
        <FetcherContentTitle
          title='駅・日付を選ぶ'
          helpText={{
            title: '駅周辺や混雑多発エリアの「混雑度予測情報」を提供します',
          }}
        />
        <FetcherAutoComplete
          id='ewj34339'
          label='駅'
          options={fetcher.stationList}
          value={fetcher.stationId}
          onChange={handleChangeStation}
          error={errors.getErrors('station').length > 0}
          errorText={errors.getErrors('station')[0]}
        />
        <DatePicker
          id={'target-date'}
          name={'targetDate'}
          label={'日付'}
          value={format(new Date(fetcher.startDateTime), 'yyyy-MM-dd')}
          min={String(process.env.REACT_APP_VALID_DATE_FROM)}
          max={String(process.env.REACT_APP_VALID_DATE_TO)}
          onChange={handleChangeDates}
          error={errors.getErrors('targetDate').length > 0}
          errorText={errors.getErrors('targetDate')[0]}
        />
        <FetcherButton
          variant='contained'
          disabled={errors.hasErrors()}
          color='primary'
          onClick={() => {
            handleSubmit();
          }}
        >
          データ絞込
        </FetcherButton>
        <Divider variant='middle' />
        <FetcherContentTitle
          title='過去の類似日を見る'
          helpText={{
            title:
              '現在の鉄道運行状況（遅延情報）に最も類似した過去の混雑情報を参考情報として提供します',
            subtitle:
              '（※）類似日は、上から順番に類似している順で表示しています',
          }}
        />
        <SimilarDateListContainer />
      </Drawer>
    </React.Fragment>
  );
};

export default Fetcher;
