import { Dispatch } from '../types';
import ActionTypes from '../constants/actionTypes';

export const startLoading = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.START_FETCH,
      payload: {},
      meta: {},
    });
  };
};
export const endLoading = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.END_FETCH,
      payload: {},
      meta: {},
    });
  };
};
