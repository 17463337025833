import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Footer from '../components/organisms/Footer';
import { RootState } from '../store/configureStore';

const footerStateToProps = (state: RootState) => ({
  peopleFlow: state.peopleFlow,
  isLoading: state.loading.isLoading,
});
function footerDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(footerStateToProps, footerDispatchToProps)(Footer);
