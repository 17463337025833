import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Link from '@material-ui/core/Link';

export const StyledAppBar = styled(AppBar)`
  ${({ theme }) => `
    position: relative;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: ${theme.zIndex.drawer + 1};
  `}
`;

export const StyledDiv = styled.div`
  flex-grow: 1;
`;

export const StyledLogoArea = styled.div`
  flex-grow: 1;
`;

export const StyledHeaderLink = styled(Link)`
  padding-top: 2px;
  padding-right: 15px;
  font-size: 0.9rem;
`;

export const BannerStyles = {
  marginBottom: '0px',
  color: 'rgb(255, 255, 255)',
  backgroundColor: 'rgb(228, 0, 79)',
  fontWeight: 400,
};

export const StyledBannerLink = styled.a`
  color: rgb(255, 255, 255);
  padding-right: 5px;
  padding-left: 5px;
  font-weight: bold !important;
`;
