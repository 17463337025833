import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

export const StyledCard = styled(Card)`
  position: relative;
  display: grid;
  height: 64px;
  width: 130px;
  bottom: 20px;
  left: calc(50% - 88px);
  justify-content: center;
  opacity: 0.8;
  border-radius: 32px;
  padding: 0px 24px;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 64px;
`;

export const StyledDateTypography = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.text.secondary};
    text-align: center;
    font-size: 1rem;
  `}
`;

export const StyledTimeTypography = styled(Typography)`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    text-align: center;
    font-size: 1.25rem;
  `}
`;
