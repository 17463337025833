import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';

export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#e4004f',
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    MuiDialog: {
      scrollPaper: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        top: '176px',
        right: '53px',
      },
      paper: {
        margin: '0px',
      },
    },
  },
});

export const StyledDiv = styled.div`
  position: absolute;
  right: 10px;
  top: 118px;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const StyledPaper = {
  style: {
    width: '250px',
    height: '200px',
  },
};

export const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
  padding-top: 0px !important;
`;
