import React, { useState, useEffect } from 'react';
import { XYPlot, XAxis, YAxis, VerticalBarSeries } from 'react-vis';

import { AxisStyle, VerticalBarStyle } from './index.styles';
import { XYProtType } from '../../../types';

type yDomainType = { min: number; max: number };

export type TICXYPlotProps = {
  width: number;
  height: number;
  color: string;
  data: Array<XYProtType>;
  tickFormatX?: any;
  tickFormatY?: any;
  tickSizeX?: number;
  tickSizeY?: number;
  tickValuesX?: Array<number>;
  tickValuesY?: Array<number>;
  yDomain?: yDomainType;
};

const getYRange = (data) => {
  return data.reduce(
    (res, row) => {
      return {
        max: Math.max(res.max, row.y),
        min: Math.min(res.min, row.y),
      };
    },
    { max: -Infinity, min: Infinity }
  );
};

const TICXYPlot: React.FunctionComponent<TICXYPlotProps> = (props) => {
  const {
    width,
    height,
    color,
    data,
    tickFormatX,
    tickFormatY,
    tickSizeX,
    tickSizeY,
    tickValuesX,
    tickValuesY,
    yDomain,
  } = props;
  const [yRange, setYRange] = useState<yDomainType>({ min: 0, max: 10 });

  useEffect(() => {
    if (yDomain) {
      setYRange({ min: yDomain.min, max: yDomain.max });
    } else {
      const newYDomain = getYRange(data);
      setYRange({ min: newYDomain.min, max: newYDomain.max });
    }
  }, [data, yDomain]);

  return (
    <XYPlot
      width={width || 192}
      height={height || 192}
      yDomain={[yRange.min, yRange.max]}
    >
      <VerticalBarSeries data={data} color={color} style={VerticalBarStyle} />
      <XAxis
        tickSize={tickSizeX}
        tickFormat={tickFormatX}
        tickValues={tickValuesX}
        style={AxisStyle}
      />
      <YAxis
        tickSize={tickSizeY}
        tickFormat={tickFormatY}
        tickValues={tickValuesY}
        style={AxisStyle}
      />
    </XYPlot>
  );
};

export default TICXYPlot;
