import { Reducer } from 'redux';
import { ActionType } from '../types';
import ActionTypes from '../constants/actionTypes';
import { produce } from 'immer';

type TimeSliderStateType = {
  currentTime: number;
};

export const initialState = {
  currentTime: 0,
};

const timeSlider: Reducer<TimeSliderStateType, ActionType> = produce(
  (draft = initialState, action) => {
    switch (action.type) {
      case ActionTypes.UPDATE_CURRENT_TIME:
        draft['currentTime'] = action.payload.currentTime;
        return draft;
    }
    return draft;
  }
);

export default timeSlider;
