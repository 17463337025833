import React from 'react';
import { ButtonProps } from '@material-ui/core/Button';
import { StyledButton } from './index.styles';

export type FetcherButtonProps = ButtonProps & { onClick: () => void };

const FetcherButton: React.FC<FetcherButtonProps> = (props) => {
  return (
    <StyledButton
      {...props}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.children}
    </StyledButton>
  );
};

export default FetcherButton;
