import React, { useEffect, useState, useRef, useCallback } from 'react';
import Button from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FastForwardIcon from '@material-ui/icons/FastForward';
import { TimeType } from '../../types';
import Timer from '../../lib/Timer';
import FPS from '../../lib/FPS';

import Slider from '@material-ui/core/Slider';
import { StyledCard, StyledTypography } from './SliderBlock.styles';

type Props = {
  onChangeSlider: any;
  value: number;
  valueFrom: number;
  valueTo: number;
  defaultTime: TimeType;
};

//@todo PlaybackControlにリネーム
export const SliderBlock: React.FC<Props> = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHandling, setIsHandling] = useState(false);
  const [sliderSpeed, setSliderSpeed] = useState(1);

  const { onChangeSlider, value, valueFrom, valueTo, defaultTime } = props;

  const animationRef = useRef<number>(0);
  const timerRef = useRef<Timer>();
  const fpsRef = useRef<FPS>();

  useEffect(() => {
    timerRef.current = new Timer({
      minTime: valueFrom,
      maxTime: valueTo,
      defaultTime,
    });
    fpsRef.current = new FPS();
  }, [valueFrom, valueTo, defaultTime]);

  const animation = useCallback(
    (time) => {
      animationRef.current = requestAnimationFrame(animation);
      if (
        isPlaying &&
        !isHandling &&
        timerRef &&
        timerRef.current &&
        fpsRef &&
        fpsRef.current
      ) {
        const deltaTime = fpsRef.current.getDeltaTime();
        timerRef.current.update(deltaTime);
        fpsRef.current.update(time);

        const nextTime = timerRef.current.getCurrentTime() || 0;
        //@todo dispatch updateCurerntTime action.
        onChangeSlider({ currentTime: nextTime });
      }
    },
    [isHandling, isPlaying, onChangeSlider]
  );

  useEffect(() => {
    animationRef.current = requestAnimationFrame(animation);
    return () => cancelAnimationFrame(animationRef.current);
  }, [animation]);

  return (
    <StyledCard>
      <Button
        color='primary'
        onClick={() => {
          timerRef.current?.togglePlayback();
          timerRef.current?.playing()
            ? setIsPlaying(true)
            : setIsPlaying(false);
        }}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </Button>
      <Slider
        min={valueFrom}
        max={valueTo}
        value={value}
        onChange={(event, newValue) => {
          typeof newValue === 'number' &&
            timerRef &&
            timerRef.current &&
            timerRef.current.setCurrentTime(newValue);
          setIsHandling(true);
          onChangeSlider({ currentTime: newValue });
        }}
        onChangeCommitted={() => {
          setIsHandling(false);
        }}
      />
      <Button
        color='primary'
        onClick={() => {
          if (timerRef && timerRef.current) {
            if (sliderSpeed > 3) {
              timerRef.current.resetSpeed();
              setSliderSpeed(1);
            } else {
              timerRef.current.setSpeed(
                timerRef.current.speed * (sliderSpeed + 1)
              );
              setSliderSpeed(sliderSpeed + 1);
            }
          }
        }}
      >
        <FastForwardIcon />
        <StyledTypography>×{sliderSpeed}</StyledTypography>
      </Button>
    </StyledCard>
  );
};
