import React from 'react';
import { StyledScreen } from './index.style';

export { StyledScreenContent as OverlayScreenContent } from './index.style';

export type OverlayScreenProps = {
  color: string;
  width?: number;
  height?: number;
  opacity?: number;
};

export type OverlayScreenContentProps = {
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  height?: number;
};

const OverlayScreen: React.FC<OverlayScreenProps> = (props) => {
  const { color, width, height, children } = props;

  return (
    <StyledScreen color={color} width={width} height={height}>
      {children}
    </StyledScreen>
  );
};

export default OverlayScreen;
