import { Dispatch } from '../types';
import ActionTypes from '../constants/actionTypes';

export const toggleLeftBar = () => {
  return (dispatch: Dispatch, getState: any) => {
    const { isOpenLeftBar } = getState();
    dispatch({
      type: ActionTypes.TOGGLE_LEFT_BAR,
      payload: isOpenLeftBar,
      meta: {},
    });
  };
};

export const openLeftBar = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.OPEN_LEFT_BAR,
      payload: {},
      meta: {},
    });
  };
};

export const closeLeftBar = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_LEFT_BAR,
      payload: {},
      meta: {},
    });
  };
};
