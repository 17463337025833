import { ActionType } from '../types';
import ActionTypes from '../constants/actionTypes';
import { produce } from 'immer';
import { Reducer } from 'redux';
import Congestion from '../models/Congestion';

export const initialState: Congestion = new Congestion();

const congestion: Reducer<Congestion, ActionType> = produce(
  (draft: Congestion = initialState, action: ActionType) => {
    switch (action.type) {
      case ActionTypes.GET_PEOPLEFLOW_STAY: {
        if (action.error) {
          draft = draft.setPeopleFlowStayEmptyData();
          draft = draft.addError(ActionTypes.GET_PEOPLEFLOW_STAY);
          return draft;
        } else {
          return draft.updatePeopleFlowStay(action.payload.data);
        }
      }
      case ActionTypes.GET_STATION_CONGESTION_LEVEL: {
        if (action.error) {
          draft = draft.setStationCongestionLevelEmptyData();
          draft = draft.addError(ActionTypes.GET_STATION_CONGESTION_LEVEL);
          return draft;
        } else {
          return draft.updateStationCongestionLevel(action.payload.data);
        }
      }
      case ActionTypes.GET_STATION_CONGESTION_RATIO: {
        if (action.error) {
          draft = draft.setStationCongestionRatioEmptyData();
          draft = draft.addError(ActionTypes.GET_STATION_CONGESTION_RATIO);
          return draft;
        } else {
          return draft.updateStationCongestionRatio(action.payload.data);
        }
      }
      case ActionTypes.DELETE_CONGESTION_ERROR_ALL: {
        return draft.deleteAllError();
      }
      default:
        return draft;
    }
  }
);

export default congestion;
