import { ActionType, Dispatch, TimeType } from '../types';
import axios, { AxiosResponse } from 'axios';
import { set } from 'date-fns';
import ActionTypes from '../constants/actionTypes';
import makeActionCreator from './actionCreators';
import { updateCurrentTime } from './timeSlider';
import { updateArcData, deleteArcData } from './map';
import PeopleFlow from '../models/PeopleFlow';
import { addAlert } from './alerts';
import { updateURL } from '../lib/URL';

//centerかh3indexどちらかのパラメータが必要
export const fetchPeopleFlowData = (params: {
  latitude: number;
  longitude: number;
  format: string; //h3 or geojson
  available: string; //date-time
  valid: string; //date-time
  h3center: string | null; // optional
  defaultTime: TimeType;
}) => {
  const apiUrl = `//${process.env.REACT_APP_API_PREFIX}.${process.env.REACT_APP_API_URL}`;
  return async (dispatch: Dispatch): Promise<ActionType> => {
    //APIの仕様は以下
    //https://mtj.stoplight.io/docs/traiire/TraIIReR.yaml/paths/~1people~1flow/get
    try {
      const response: AxiosResponse = await axios.get(`${apiUrl}/people/flow`, {
        params,
      });
      if (response.data.length === 0) {
        dispatch(deleteArcData());
        throw new Error('人流データが見つかりませんでした。');
      }
      const peopleFlow: PeopleFlow = new PeopleFlow();
      const data = peopleFlow.updateData(response.data);
      const currentTime =
        set(new Date(params.available), params.defaultTime).getTime() / 1000;
      updateURL(params.latitude, params.longitude, params.available);
      dispatch(updateCurrentTime({ currentTime: currentTime }));
      dispatch(
        updateArcData({
          latitude: params.latitude,
          longitude: params.longitude,
          h3center: params.h3center,
          data: response.data,
        })
      );
      return dispatch(fetchPeopleFlowDataSuccess({ data: data }));
    } catch (err) {
      dispatch(
        addAlert({
          type: 'error',
          message: `${err}`,
        })
      );
      return dispatch(fetchPeopleFlowDataFailed(err));
    }
  };
};

const fetchPeopleFlowDataSuccess = makeActionCreator(
  ActionTypes.FETCH_PEOPLEFLOW,
  ['data']
);

const fetchPeopleFlowDataFailed = (e: any) => {
  return {
    type: ActionTypes.FETCH_PEOPLEFLOW,
    payload: null,
    meta: null,
    error: e,
  };
};

export const setFetcherStation = (
  h3Index: string,
  stationId: string,
  latitude: number,
  longitude: number
) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.SET_STATION,
      payload: { h3Index, stationId, latitude, longitude },
      meta: {},
    });
  };
};

export const updateFetcherStation = (
  stationId: string,
  latitude: string,
  longitude: string
) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_STATION,
      payload: { stationId, latitude, longitude },
      meta: {},
    });
  };
};

export const updateFetcherDate = (
  startDateTime: string,
  endDateTime: string
) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.UPDATE_DATES,
      payload: { startDateTime, endDateTime },
      meta: {},
    });
  };
};
export const openFetcher = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.OPEN_FETCHER,
      payload: {},
      meta: {},
    });
  };
};

export const closeFetcher = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_FETCHER,
      payload: {},
      meta: {},
    });
  };
};

export const fetched = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.FETCHED,
      payload: {},
      meta: {},
    });
  };
};
