import { connect, ConnectedProps } from 'react-redux';
import Fetcher from '../components/organisms/Fetcher';
import {
  fetchPeopleFlowData,
  setFetcherStation,
  updateFetcherStation,
  updateFetcherDate,
  openFetcher,
  closeFetcher,
  fetched,
} from '../actions/fetcher';
import { getAroundStations } from '../actions/aroundStations';
import { openLeftBar, closeLeftBar } from '../actions/leftBar';
import {
  getPeopleFlowStay,
  getStationCongestionLevel,
  getStationCongestionRatio,
  deleteCongestionErrorAll,
} from '../actions/congestion';
import { updateCenter } from '../actions/map';
import { startLoading, endLoading } from '../actions/loading';
import { deleteAlertAll } from '../actions/alerts';
import { updateSelectSimilarDateIndex } from '../actions/similarDate';

import { RootState } from '../store/configureStore';

const fetcherState = (state: RootState) => ({
  fetcher: state.fetcher,
  map: state.map,
  aroundStations: state.aroundStations,
  peopleFlow: state.peopleFlow,
  loading: state.loading,
});

const fetcherDispatch = {
  fetchPeopleFlowData,
  openLeftBar,
  closeLeftBar,
  getAroundStations,
  getPeopleFlowStay,
  getStationCongestionLevel,
  getStationCongestionRatio,
  setFetcherStation,
  updateFetcherStation,
  updateFetcherDate,
  updateCenter,
  openFetcher,
  closeFetcher,
  fetched,
  startLoading,
  endLoading,
  deleteAlertAll,
  deleteCongestionErrorAll,
  updateSelectSimilarDateIndex,
};

const connector = connect(fetcherState, fetcherDispatch);

export type FetcherPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Fetcher);
