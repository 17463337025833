import { Dispatch } from '../types';
import ActionTypes from '../constants/actionTypes';
import makeActionCreator from './actionCreators';

export const deleteAlertAll = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_ALERT_ALL,
      payload: {},
      meta: {},
    });
  };
};

export const addAlert = makeActionCreator(ActionTypes.ADD_ALERT, [
  'type',
  'message',
]);
