import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { HelpTooltip } from '../atoms/CustomizedTooltip';
import { hexFillColors } from '../../lib/Color';

import {
  StyledGridContainer,
  StyledGridColorPalette,
  StyledColorSquare,
  StyledHelpOutlineIcon,
} from './HexagonTooltip.styles';

const HexagonTooltip: React.FC = () => {
  return (
    <HelpTooltip
      title={
        '選択したエリアの15分毎の混雜量をLv1〜Lv10の10段階のレベルで示しています'
      }
      content={
        <StyledGridContainer
          container
          direction='column'
          justify='space-between'
          alignItems='flex-start'
        >
          <Grid item>
            <Typography variant='body2' color='inherit'>
              混雑量の凡例
            </Typography>
          </Grid>
          <StyledGridColorPalette
            container
            item
            direction='row'
            justify='space-evenly'
            alignItems='center'
          >
            {hexFillColors.map((color, index) => {
              return (
                <Grid item xs={1} key={index}>
                  <StyledColorSquare color={color} />
                </Grid>
              );
            })}
          </StyledGridColorPalette>
          <Grid
            container
            item
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='caption' color='inherit'>
                低い
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='caption' color='inherit'>
                高い
              </Typography>
            </Grid>
          </Grid>
        </StyledGridContainer>
      }
      placement='bottom-end'
    >
      <StyledHelpOutlineIcon color='primary' fontSize='small' />
    </HelpTooltip>
  );
};

export default HexagonTooltip;
