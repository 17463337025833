import { connect, ConnectedProps } from 'react-redux';
import TimeSlider from '../components/organisms/TimeSlider';
import { RootState } from '../store/configureStore';
import { updateCurrentTime } from '../actions/timeSlider';

//@todo state type
const mapState = (state: RootState) => ({
  peopleFlow: state.peopleFlow,
  valueFrom: state.peopleFlow.date.from,
  valueTo: state.peopleFlow.date.to,
  currentTime: state.timeSlider.currentTime,
  isOpenLeftBar: state.isOpenLeftBar,
  loading: state.loading,
});

const mapDispatch = {
  updateCurrentTime,
};

const connector = connect(mapState, mapDispatch);

export type TimeSliderProps = ConnectedProps<typeof connector>;

export default connector(TimeSlider);
