import { Reducer } from 'redux';
import { ActionType, AlertType } from '../types';
import ActionTypes from '../constants/actionTypes';
import { produce } from 'immer';

const alerts: Reducer<Array<AlertType>, ActionType> = produce(
  (draft: Array<AlertType> = [], action) => {
    switch (action.type) {
      case ActionTypes.DELETE_ALERT_ALL:
        return [];
      case ActionTypes.ADD_ALERT:
        const alert = action.payload;
        return [...draft, alert];
      default:
        return draft;
    }
  }
);

export default alerts;
