import React, { useState } from 'react';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import TuneIcon from '@material-ui/icons/Tune';
import Switch from '@material-ui/core/Switch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';

import HexagonTooltip from '../molecules/HexagonTooltip';

import {
  theme,
  StyledDiv,
  StyledDialogContent,
  StyledPaper,
} from './MapOption.styles';

type Props = {
  isLoading: boolean;
  visibleLayer: any;
  onChange: any;
};

export const MapOption: React.FC<Props> = (props) => {
  const { isLoading, visibleLayer, onChange } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return !isLoading ? (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StyledDiv>
          <Grid
            container
            direction='row'
            justify='space-around'
            alignItems='center'
          >
            <Grid item>
              <IconButton
                aria-controls='map-option-menu'
                aria-haspopup='true'
                onClick={handleClick}
                color='primary'
              >
                <TuneIcon />
              </IconButton>
              <Dialog
                maxWidth='xs'
                open={open}
                onClose={handleClose}
                aria-labelledby='map-option-dialog-title'
                PaperProps={StyledPaper}
              >
                <StyledDialogContent>
                  <List
                    subheader={<ListSubheader>マップオプション</ListSubheader>}
                  >
                    <ListItem>
                      <ListItemText id='arc' primary='移動量表示' />
                      <ListItemSecondaryAction>
                        <Switch
                          checked={visibleLayer.arc}
                          onChange={onChange}
                          name='arc'
                          color='primary'
                          size='small'
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </StyledDialogContent>
              </Dialog>
            </Grid>
            <Grid item>
              <HexagonTooltip />
            </Grid>
          </Grid>
        </StyledDiv>
      </ThemeProvider>
    </MuiThemeProvider>
  ) : null;
};
