import { combineReducers } from 'redux';
import fetcher from './fetcher';
import map from './map';
import peopleFlow from './peopleFlow';
import timeSlider from './timeSlider';
import aroundStations from './aroundStations';
import isOpenLeftBar from './leftBar';
import congestion from './congestion';
import loading from './loading';
import alerts from './alerts';
import similarDate from './similarDate';

export default function createRootReducer() {
  return combineReducers({
    fetcher,
    map,
    aroundStations,
    peopleFlow,
    timeSlider,
    isOpenLeftBar,
    congestion,
    loading,
    alerts,
    similarDate,
  });
}
