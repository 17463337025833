export default class FPS {
  deltaTime: number;
  previousTime: number;
  interval: number; // ミリ秒
  count: number;
  deltaSum: number;
  fps: number;

  constructor(interval: number = 1000) {
    this.deltaTime = 0;
    this.previousTime = 0;
    this.interval = interval;
    this.count = 0;
    this.deltaSum = 0;
    this.fps = 0;
  }

  // requestAnimationFrameの中で呼び出す。引数のtimeをそのまま渡す。
  update(animationTime: number) {
    this.count += 1;
    this.deltaSum += this.deltaTime;

    if (this.deltaSum >= this.interval) {
      this.fps = this.count;
      this.count = 0;
      this.deltaSum = 0;
      //console.log(this.fps);
    }
    this.deltaTime = animationTime - this.previousTime;

    this.previousTime = animationTime;
  }

  getFPS() {
    return this.fps;
  }

  getDeltaTime() {
    return this.deltaTime;
  }
}
