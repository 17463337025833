import React, { useState, useEffect } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import { StyledDiv, StyledSpan } from './ShareButtonList.styles';
import { geoToH3 } from 'h3-js';
import { format } from 'date-fns';
import { getParamLatLon, getParamDate } from '../../lib/URL';

const ICON_SIZE = 23;
const REACT_APP_HEXAGON_RESOLUTION = Number(
  process.env.REACT_APP_HEXAGON_RESOLUTION
);

type Props = {
  isLoading: boolean;
};

const ShareButtonList: React.FC<Props> = (props) => {
  const { isLoading } = props;
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (!isLoading) {
      const latlon = getParamLatLon();
      const date = getParamDate();
      const base_url = new URL(document.URL).origin + '/?';
      const h3Index = geoToH3(
        latlon[0],
        latlon[1],
        REACT_APP_HEXAGON_RESOLUTION
      );
      const query = new URLSearchParams({
        date: format(new Date(date), 'yyyy-MM-dd'),
        h3Index: h3Index,
      });
      setUrl(base_url + query.toString());
    }
  }, [isLoading]);

  return (
    <StyledDiv>
      <StyledSpan>
        <TwitterShareButton
          disabled={isLoading}
          url={url}
          hashtags={['peopleflow', 'traisare', 'MaaSTechJapan']}
        >
          <TwitterIcon size={ICON_SIZE} round />
        </TwitterShareButton>
      </StyledSpan>
      <StyledSpan>
        <FacebookShareButton
          disabled={isLoading}
          url={url}
          hashtag='#MaaSTechJapan'
        >
          <FacebookIcon size={ICON_SIZE} round />
        </FacebookShareButton>
      </StyledSpan>
    </StyledDiv>
  );
};

export default ShareButtonList;
