import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

export const drawerWidth = '413px';

type DrawerToggleType = {
  open: boolean;
};
export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#fff',
    },
  },
});

export const StyledDrawerDiv = styled.div.attrs((props: DrawerToggleType) => ({
  open: props.open,
}))`
  position: relative;
  width: ${(props) => (props.open ? drawerWidth : '0px')};
  transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;

export const StyledPaper = styled.div`
  margin-top: 108px;
  height: calc(100% - 64px);
`;

export const StyledPaperDiv = styled.div.attrs((props: DrawerToggleType) => ({
  open: props.open,
}))`
  width: ${(props) => (props.open ? drawerWidth : '0px')};
  transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow: ${(props) => (props.open ? 'overlay' : 'hidden')};
`;

export const StyledDrawerToggle = styled.div`
  ${({ theme }) => `
    display: block;
    position: absolute;
    z-index: ${theme.zIndex.drawer - 1};
    background-color: rgba(255,255,255,0.7);
    top: 40px;
    left: 100%;
  `}
`;

export const StyledDivider = styled(Divider)`
  background-color: #818181;
  opacity: 0.8;
`;

export const ButtonDiv = styled.div`
  padding: 6px 6px;
  font-size: 0.875rem;
  line-height: 1.75;
  border-radius: 4px;
  border: 0;
  cursor: auto;
  background-color: #e4004f;
  opacity: 1;
`;
