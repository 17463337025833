export const increaseColor = '#e4004f';
export const decreaseColor = '#46A3DB';
export const defaultColor = '#FFF';

export const ChipColor = {
  backgroundColor: '#00CC66',
  color: defaultColor,
};

export const ChipWidth = {
  width: '150px',
};
