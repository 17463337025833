import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import AlertList from '../components/molecules/AlertList';
import { RootState } from '../store/configureStore';

const stateToProps = (state: RootState) => ({
  alerts: state.alerts,
  isLoading: state.loading.isLoading,
});
function dispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(stateToProps, dispatchToProps)(AlertList);
