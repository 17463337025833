import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import PeopleFlow from '../../models/PeopleFlow';
import HexagonScale from '../atoms/HexagonScale';
import MapAttribution from '../atoms/MapAttribution';
import {
  StyledFooterDiv,
  StyledHexagonDiv,
  StyledMapAttributionDiv,
  MapboxLogo,
} from '../organisms/Footer.styles';

type Props = {
  peopleFlow: PeopleFlow;
  isLoading: boolean;
};

const Footer: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { peopleFlow, isLoading } = props;

  const modalDescription = 
  `「混雑統計®️」データは、NTTドコモが提供するアプリケーション(※)の利用者より、許諾を得た上で送信される携帯電話の位置 情報を、NTTドコモが総体的かつ統計的に加工を行ったデータ。位置情報は最短5分毎に測位されるGPSデータ(緯度経度 情報)であり、個人を特定する情報は含まれない。

    ※ドコモ地図ナビサービス(地図アプリ・ご当地ガイド)等の一部のアプリ`

  return (
    <StyledFooterDiv>
      {peopleFlow.data.length !== 0 && !isLoading ? (
        <StyledHexagonDiv>
          <HexagonScale
            fill={theme.palette.primary.main}
            opacity={'0.8'}
            height={47}
            width={47}
            label={'348m'}
          />
        </StyledHexagonDiv>
      ) : null}
      <StyledMapAttributionDiv>
        <MapAttribution component='modal' url='#' description={modalDescription}>
          「混雑統計(R)」
        </MapAttribution>
        <MapAttribution component='label'>
          (C)ZENRIN DataCom CO., LTD.
        </MapAttribution>
        <MapAttribution component='a' url='/assets/static/attribution.html'>
          PeopleFlow利用規約
        </MapAttribution>
        <MapAttribution component='a' url='https://www.mapbox.com/about/maps/'>
          (C)Mapbox
        </MapAttribution>
        <MapAttribution
          component='a'
          url='https://www.openstreetmap.org/about/'
        >
          (C)OpenStreetMap
        </MapAttribution>
      </StyledMapAttributionDiv>
      <MapboxLogo
        href='http://mapbox.com/about/maps'
        className='mapbox-wordmark'
        target='_blank'
        rel='noopener noreferrer'
      >
        Mapbox
      </MapboxLogo>
    </StyledFooterDiv>
  );
};

export default Footer;
