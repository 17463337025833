import { ActionType } from '../types';
import ActionTypes from '../constants/actionTypes';
import { produce } from 'immer';
import { Reducer } from 'redux';
// 駅データの読み込み
import stations from '../assets/json/aroundStations_sample.json';

export type StationType = { id: number; label: string; value: string };
type TrainRouteType = {
  label: string;
  value: string;
  color: string;
  adjacentStations: Array<StationType>;
};

export type AroundStationStateType = {
  station: StationType;
  train_routes: TrainRouteType[];
};

export const initialState: AroundStationStateType[] = [];

const aroundStations: Reducer<AroundStationStateType[], ActionType> = produce(
  (draft = initialState, action: ActionType) => {
    switch (action.type) {
      case ActionTypes.INIT_AROUND_STATIONS: {
        return initialState;
      }
      case ActionTypes.GET_AROUND_STATIONS: {
        // @todo 本番APIでき次第修正
        // const aroundStations = action.payload;
        const aroundStations = stations;
        return { ...draft, aroundStations };
      }
      default:
        return draft;
    }
  }
);

export default aroundStations;
