import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import FetcherContainer from '../../containers/FetcherContainer';
import ShareButtonListContainer from '../../containers/ShareButtonListContainer';
import HeaderLogo from '../molecules/HeaderLogo';
import Banner from 'react-js-banner';
import {
  StyledDiv,
  StyledAppBar,
  StyledLogoArea,
  StyledHeaderLink,
  BannerStyles,
  StyledBannerLink,
} from './Header.styles';

const Header: React.FunctionComponent = () => {
  return (
    <StyledDiv>
      <StyledAppBar>
        <Banner showBanner={true} css={BannerStyles}>
          <span>
            MaaSデータ基盤「TraISARE」については
            <StyledBannerLink
              href='https://www.maas.co.jp/business/platform-development/'
              target='_blank'
              rel='noopener noreferrer'
            >
              こちら
            </StyledBannerLink>
            を参照ください。
          </span>
          <span>
            ソリューションのご相談は
            <StyledBannerLink
              href='https://forms.office.com/Pages/ResponsePage.aspx?id=MtnIeK3tMEexbLLR4ScCQINuwlcbe-tIvEAZtVnJjuRURThIODFYWDNNS0lUMUo5Q0lWVFRZMVU5MS4u'
              target='_blank'
              rel='noopener noreferrer'
            >
              こちら
            </StyledBannerLink>
            からお問い合わせください
          </span>
        </Banner>
        <Toolbar>
          <StyledLogoArea>
            <HeaderLogo />
          </StyledLogoArea>
          <StyledHeaderLink
            href='https://docs.peopleflow.maas.jp/'
            target='_blank'
            rel='noopener noreferrer'
          >
            PeopleFlowについて
          </StyledHeaderLink>
          <ShareButtonListContainer />
          <FetcherContainer />
        </Toolbar>
      </StyledAppBar>
    </StyledDiv>
  );
};

export default Header;
