import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DetailHeader from '../atoms/DetailHeader';

import { format, fromUnixTime } from 'date-fns';

type Props = {
  from: number;
  to: number;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | undefined;
};

const SearchCondition = (props: Props) => {
  const { from, to, variant } = props;

  const text =
    from & to
      ? `${format(fromUnixTime(from), 'yyyy/MM/dd HH:mm')} - ${format(
          fromUnixTime(to),
          'yyyy/MM/dd HH:mm'
        )}`
      : '-';

  return (
    <Card>
      <CardContent>
        <DetailHeader title={'選択時刻'} />
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Typography variant={variant}>{text}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SearchCondition;
