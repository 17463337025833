import React, { useEffect } from 'react';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { KPIListsPropsFromRedux } from '../../containers/KPIListsContainer';
import {
  theme,
  StyledDrawerDiv,
  StyledPaper,
  StyledPaperDiv,
  StyledDrawerToggle,
  StyledDivider,
  ButtonDiv,
} from './KPILists.styles';
import { HorizontalToggleButton } from '../atoms/ToggleButton';
import OverlayScreen, { OverlayScreenContent } from '../atoms/OverlayScreen';
// @todo delete or implement API
// import StationList from '../molecules/StationList';
import StationCongestion from '../molecules/StationCongestion';
import SearchCondition from '../molecules/SearchCondition';

type Props = KPIListsPropsFromRedux;

const KPILists = (props: Props) => {
  const {
    isOpenLeftBar,
    toggleLeftBar,
    openLeftBar,
    searchFrom,
    searchTo,
  } = props;
  const {
    peopleFlowStay,
    stationCongestionLevel,
    statoinCongestionRatio,
    errors,
  } = props.congestion;
  const { isLoading } = props.loading;
  const { isFetched } = props.fetcher;

  const handleDrawerToggle = () => {
    toggleLeftBar();
  };
  useEffect(() => {
    openLeftBar();
  }, [openLeftBar]);

  return !isLoading ? (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StyledDrawerDiv open={isOpenLeftBar}>
          <Drawer
            anchor='left'
            variant='permanent'
            PaperProps={{ component: StyledPaper }}
          >
            <StyledPaperDiv open={isOpenLeftBar}>
              <List>
                <SearchCondition from={searchFrom} to={searchTo} variant='h6' />
                <StyledDivider />
                {/* // @todo delete or implement API */}
                {/* <StationList stations={stations} /> */}
                <StyledDivider />
                <StationCongestion
                  levelData={stationCongestionLevel}
                  timeData={peopleFlowStay}
                  ratioData={statoinCongestionRatio}
                  errors={errors}
                />
                <StyledDivider />
              </List>
            </StyledPaperDiv>
            {!isFetched ? (
              <OverlayScreen color='black' opacity={0.8}>
                <OverlayScreenContent
                  justify='center'
                  alignItems='center'
                  height={250}
                >
                  <ButtonDiv>データ絞込</ButtonDiv>
                  <Typography>で駅周辺情報が表示されます</Typography>
                </OverlayScreenContent>
              </OverlayScreen>
            ) : null}
          </Drawer>
          <StyledDrawerToggle>
            <HorizontalToggleButton
              open={isOpenLeftBar}
              onClick={handleDrawerToggle}
              color={'secondary'}
            />
          </StyledDrawerToggle>
        </StyledDrawerDiv>
      </ThemeProvider>
    </MuiThemeProvider>
  ) : (
    <div></div>
  );
};

export default KPILists;
