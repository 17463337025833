export const CookieBannerStyles = {
  banner: {
    fontFamily: 'Source Sans Pro',
    height: 71.5,
    backgroundSize: '30px 30px',
    backgroundColor: 'rgba(52, 64, 81, 0.88)',
    fontSize: '16px',
    fontWeight: 600,
    position: 'fixed',
    display: 'flex',
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
  },
  button: {
    borderRadius: 0,
    width: 55.84,
    height: 39.4,
    lineHeight: '32px',
    fontSize: '.9em',
    fontWeight: 700,
    opacity: 1,
    right: 20,
    marginTop: -18,
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(228, 0, 79)',
  },
  message: {
    display: 'block',
    padding: '1em 1.8em',
    lineHeight: 1.3,
    textAlign: 'left',
    marginRight: 100,
    color: 'white',
  },
  link: {
    fontWeight: 'bold',
  },
};
