import { set, fromUnixTime } from 'date-fns';
import { TimeType } from '../types';

type Props = {
  maxTime: number;
  minTime: number;
  defaultTime: TimeType;
};

export default class Timer {
  currentTime: number;
  isLoop: boolean;
  speed: number;
  minTime: number;
  maxTime: number;
  time: number;
  previousDeltaTime: number;
  isPlaying: boolean;

  defaultSpeed: number = 15 * 60;

  constructor(props: Props) {
    const { maxTime, minTime, defaultTime } = props;

    this.currentTime = set(fromUnixTime(minTime), defaultTime).getTime() / 1000;
    this.isLoop = true;
    this.minTime = minTime;
    this.maxTime = maxTime;
    this.speed = this.defaultSpeed;
    this.time = 0;
    this.previousDeltaTime = 0;
    this.isPlaying = false;
  }

  update(deltaTime: number) {
    if (!this.isPlaying) return;

    if (this.currentTime < this.minTime) {
      this.currentTime = this.minTime;
    }

    if (0 < deltaTime) {
      this.currentTime += this.speed / deltaTime;
    }
    this.time += deltaTime;

    if (this.currentTime >= this.maxTime && this.isLoop) {
      this.currentTime = this.minTime;
      // @todo 削除予定。　パフォーマンスデバッグ用。開始時刻から終了時刻までのアニメーションが何秒かかったか。
      // console.log(`${this.time / 1000} sec/loop`);
      this.time = 0;
    }

    if (this.currentTime > this.maxTime) {
      this.currentTime = this.maxTime;
    }
  }

  pause() {
    this.isPlaying = false;
  }

  play() {
    this.isPlaying = true;
  }

  playing() {
    return this.isPlaying;
  }

  togglePlayback() {
    this.isPlaying = !this.isPlaying;
  }

  getPreviousDeltaTime() {
    return this.previousDeltaTime;
  }

  getCurrentTime() {
    return this.currentTime;
  }

  setCurrentTime(newTime: number) {
    this.currentTime = newTime;
  }

  setSpeed(newSpeed: number) {
    this.speed = newSpeed;
  }

  resetSpeed() {
    this.speed = this.defaultSpeed;
  }
}
