import React from 'react';
import Hexagon from 'react-svg-hexagon';
import { StyledParagraph } from './index.styles';

export type HexagonScaleProps = {
  fill: string;
  opacity: string;
  width: number;
  height: number;
  label: string;
};

const HexagonScale: React.FC<HexagonScaleProps> = (props) => {
  const { fill, opacity, width, height, label } = props;

  return (
    <Hexagon
      render={({ Polygon, Content, Svg }) => {
        return (
          <Svg width={width} height={height}>
            <Polygon fill={fill} fillOpacity={opacity} />
            <Content>
              <StyledParagraph>{label}</StyledParagraph>
            </Content>
          </Svg>
        );
      }}
    />
  );
};

export default HexagonScale;
