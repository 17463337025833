import { connect, ConnectedProps } from 'react-redux';
import KPILists from '../components/organisms/KPILists';
import { toggleLeftBar, openLeftBar } from '../actions/leftBar';
import { RootState } from '../store/configureStore';

//@todo state type
const kpiState = (state: RootState) => ({
  fetcher: state.fetcher,
  isOpenLeftBar: state.isOpenLeftBar,
  searchFrom: state.peopleFlow.date.from,
  searchTo: state.peopleFlow.date.to,
  aroundStations: state.aroundStations,
  congestion: state.congestion,
  loading: state.loading,
});

const kpiDispatch = {
  toggleLeftBar,
  openLeftBar,
};

const connector = connect(kpiState, kpiDispatch);

export type KPIListsPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(KPILists);
