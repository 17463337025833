import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const StyledTextField = styled(TextField)`
  ${({ theme }) => `
    margin-left: ${theme.spacing(3)}px;
    margin-right: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(2)}px;
    width: 330px;
  `}
`;
