import React from 'react';
import CookieBanner from 'react-cookie-banner';
import { CookieBannerStyles } from './index.styles';

export const Cookie = () => {
  return (
    <CookieBanner
      message='本サービスで利用状況を分析するため、Cookie（クッキー）を利用しています。詳細は当社のプライバシーポリシー及び Cookie ポリシー を確認してください。'
      onAccept={() => {}}
      cookie='user-has-accepted-cookies'
      styles={CookieBannerStyles}
      link={
        <a
          href='https://docs.peopleflow.maas.jp/#cookie'
          target='_blank'
          rel='noopener noreferrer'
        >
          詳細
        </a>
      }
      buttonMessage='同意'
    />
  );
};
