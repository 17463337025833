import { createMuiTheme } from '@material-ui/core/styles';

export const ErrorTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: 'rgb(97, 26, 21)',
        backgroundColor: 'rgb(253, 236, 234)',
      },
    },
  },
});

export const HtmlTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#e4004f',
    },
    secondary: {
      main: 'rgba(255, 255, 255, 0.6)',
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: '#f5f5f9',
        maxWidth: 250,
        fontSize: 10,
        border: '1px solid #dadde9',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
  },
});
