import vest, { group, test } from 'vest';
import enforce from 'vest/enforceExtended';
import { FetcherStateType } from '../../reducers/fetcher';
import { format } from 'date-fns';

const DATE_FROM = process.env.REACT_APP_VALID_DATE_FROM
  ? new Date(process.env.REACT_APP_VALID_DATE_FROM)
  : new Date('2020-01-01');
const DATE_TO = process.env.REACT_APP_VALID_DATE_TO
  ? new Date(process.env.REACT_APP_VALID_DATE_TO)
  : new Date('2021-05-31');

export default vest.create(
  'fetcher',
  (data: FetcherStateType & { targetDate: string }, { currentField }) => {
    //実行するテストを制限
    if (currentField === 'targetDate') {
      vest.only.group('dateRange');
    } else if (currentField) {
      vest.only(currentField);
    }

    group('PEOPE_FLOW', () => {
      test('stationId', '駅を選択してください。', () => {
        enforce(data.stationId).isNotEmpty();
      });

      group('dateRange', () => {
        test('targetDate', '日付を指定してください。', () => {
          enforce(data.startDateTime).isNotEmpty();
        });
        test(
          'targetDate',
          `${format(DATE_FROM, 'yyyy年M月d日')}から${format(
            DATE_TO,
            'yyyy年M月d日'
          )}の範囲で日付を指定して下さい。`,
          () => {
            const targetDate = new Date(data.targetDate);
            if (DATE_FROM <= targetDate && targetDate <= DATE_TO) {
              enforce(data.targetDate).isTruthy();
            } else {
              enforce(data.targetDate).isFalsy();
            }
          }
        );
      });
    });
  }
);
