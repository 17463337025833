import styled from 'styled-components';
import List from '@material-ui/core/List';

export const StyledDiv = styled.div`
  ${({ theme }) => `
    height: 45%;
    display: grid;
    flex: 1 0 auto;
    margin-left: ${theme.spacing(3)}px;
    margin-right: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(3)}px;
  `}
`;

export const StyledList = styled(List)`
  overflow-y: auto;
`;
