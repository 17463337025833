import { StationListType } from '../types';
import { geoToH3 } from 'h3-js';

const HEXAGON_RESOLUTION = Number(process.env.REACT_APP_HEXAGON_RESOLUTION);

export const filterStationList = (
  targetH3Index: string,
  stationList: Array<StationListType>
) => {
  return stationList.filter((data: StationListType) => {
    const [longitude, latitude] = data.geometry.coordinates;
    const stationH3Index = geoToH3(latitude, longitude, HEXAGON_RESOLUTION);
    return targetH3Index === stationH3Index;
  });
};
