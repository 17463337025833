import { H3HexagonType } from '../types';

export const h3HexagonLayerProps = {
  id: 'h3-hexagon-layer',
  pickable: true,
  wireframe: false,
  filled: true,
  extruded: false,
  stroked: true,
  lineWidthUnites: 'pixels',
  lineWidthScale: 2,
  coverage: 0.97,
  lineWidthMinPixels: 1,
  elevationScale: 0,
  getLineWidth: 5,
  getHexagon: (d: H3HexagonType) => {
    return d.hexagon_id;
  },
  getElevation: (d: H3HexagonType) => d.value,
};
