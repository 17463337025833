import { ActionType, Dispatch } from '../types';
import axios, { AxiosResponse } from 'axios';
import ActionTypes from '../constants/actionTypes';
import makeActionCreator from './actionCreators';
import { addAlert } from './alerts';

export function getPeopleFlowStay(params: {
  h3center: string;
  available: string; //date-time
  valid: string; //date-time
}) {
  return async (dispatch: Dispatch): Promise<ActionType> => {
    try {
      const apiUrl = `//${process.env.REACT_APP_API_PREFIX}.${process.env.REACT_APP_API_URL}`;
      const response: AxiosResponse = await axios.get(
        `${apiUrl}/people/flow/stay`,
        {
          params,
          timeout: 100000,
        }
      );
      if (response.data.average_stay_count_per_1_hours.length === 0) {
        throw new Error('駅情報データが見つかりませんでした。（時間別混雑度）');
      }
      return dispatch(getPeopleFlowStaySuccess({ data: response.data }));
    } catch (err) {
      dispatch(
        addAlert({
          type: 'error',
          message: `${err}`,
        })
      );
      return dispatch(requestFailed(ActionTypes.GET_PEOPLEFLOW_STAY, err));
    }
  };
}

export function getStationCongestionLevel(
  h3center: string,
  params: {
    available: string; //date-time
    valid: string; //date-time
  }
) {
  return async (dispatch: Dispatch): Promise<ActionType> => {
    try {
      const apiUrl = `//${process.env.REACT_APP_API_PREFIX}.${process.env.REACT_APP_API_URL}`;
      const response: AxiosResponse = await axios.get(
        `${apiUrl}/nodes/stations/congestion/${h3center}`,
        {
          params,
          timeout: 100000,
        }
      );
      if (response.data.level === -1) {
        throw new Error('駅情報データが見つかりませんでした。（混雑度平均）');
      }
      return dispatch(
        getStationCongestionLevelSuccess({ data: response.data })
      );
    } catch (err) {
      dispatch(
        addAlert({
          type: 'error',
          message: `${err}`,
        })
      );
      return dispatch(
        requestFailed(ActionTypes.GET_STATION_CONGESTION_LEVEL, err)
      );
    }
  };
}

export function getStationCongestionRatio(
  h3center: string,
  params: {
    available: string; //date-time
    valid: string; //date-time
  }
) {
  return async (dispatch: Dispatch): Promise<ActionType> => {
    try {
      const apiUrl = `//${process.env.REACT_APP_API_PREFIX}.${process.env.REACT_APP_API_URL}`;
      const response: AxiosResponse = await axios.get(
        `${apiUrl}/nodes/stations/congestion_ratio/${h3center}`,
        {
          params,
          timeout: 100000,
        }
      );
      return dispatch(
        getStationCongestionRatioSuccess({ data: response.data })
      );
    } catch (err) {
      dispatch(
        addAlert({
          type: 'error',
          message: `${err}`,
        })
      );
      return dispatch(
        requestFailed(ActionTypes.GET_STATION_CONGESTION_RATIO, err)
      );
    }
  };
}

const getPeopleFlowStaySuccess = makeActionCreator(
  ActionTypes.GET_PEOPLEFLOW_STAY,
  ['data']
);

const getStationCongestionLevelSuccess = makeActionCreator(
  ActionTypes.GET_STATION_CONGESTION_LEVEL,
  ['data']
);

const getStationCongestionRatioSuccess = makeActionCreator(
  ActionTypes.GET_STATION_CONGESTION_RATIO,
  ['data']
);

const requestFailed = (type: string, e: any) => {
  return {
    type: type,
    payload: null,
    meta: null,
    error: e,
  };
};

export const deleteCongestionErrorAll = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_CONGESTION_ERROR_ALL,
      payload: {},
      meta: {},
    });
  };
};
