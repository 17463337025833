import { ActionType } from '../types';

type actionCreatorType = (payload?: any, meta?: any) => ActionType;

const makeActionCreator = (
  type: string,
  payloadNames?: Array<string>,
  metaNames?: Array<string>
): actionCreatorType => {
  return (payload?, meta?) => {
    const action = { type, payload: {}, meta: {} };
    if (payloadNames && payloadNames.length > 0) {
      payloadNames.forEach((keyName, index) => {
        action['payload'][keyName] = payload[keyName];
      });
    }
    if (metaNames && metaNames.length > 0) {
      metaNames.forEach((keyName, index) => {
        action['meta'][keyName] = meta[keyName];
      });
    }
    return action;
  };
};

export default makeActionCreator;
