import { ActionType, Dispatch } from '../types';
// @todo 本番APIでき次第修正
// import axios from 'axios';
import ActionTypes from '../constants/actionTypes';

export const initAroundStations = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.INIT_AROUND_STATIONS,
      payload: {},
      meta: {},
    });
  };
};

export const getAroundStations = (params: { h3index: string }) => {
  return async (dispatch: Dispatch, getState: any): Promise<ActionType> => {
    let res = {};
    try {
      // @todo 本番APIでき次第修正
      // await axios
      //   .get(`/api/stations`, {
      //     params,
      //   })
      //   .then(function (response) {
      //     // handle success
      //     res = response;
      //   })
      //   .catch(function (error) {
      //     // handle error
      //     console.log(error);
      //   })
      //   .then(function () {
      //     // always executed
      //   });
      return dispatch(getAroundStationsSuccess(res));
    } catch (err) {
      return getAroundStationsSuccess(res);
    }
  };
};

export const getAroundStationsSuccess = (res) => {
  return {
    type: ActionTypes.GET_AROUND_STATIONS,
    payload: res.data,
    meta: {},
  };
};
