import React from 'react';
import LoadingIcon from '../atoms/LoadingIcon';
import OverlayScreen, { OverlayScreenContent } from '../atoms/OverlayScreen';

type Props = {
  isLoading: boolean;
};

export const LoadingScreen: React.FC<Props> = (props) => {
  const { isLoading } = props;
  return isLoading ? (
    <OverlayScreen color={'black'}>
      <OverlayScreenContent justify='center' alignItems='center'>
        <LoadingIcon />
      </OverlayScreenContent>
    </OverlayScreen>
  ) : null;
};
