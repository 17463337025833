const ActionTypes = {
  //map
  FETCH_PEOPLEFLOW: 'FETCH_PEOPLEFLOW',
  UPDATE_CENTER: 'UPDATE_CENTER',
  UPDATE_ARC_DATA: 'UPDATE_ARC_DATA',
  DELETE_ARC_DATA: 'DELETE_ARC_DATA',

  //fetcher
  SET_STATION: 'SET_STATION',
  UPDATE_STATION: 'UPDATE_STATION',
  UPDATE_DATES: 'UPDATE_DATES',
  START_FETCH: 'START_FETCH',
  END_FETCH: 'END_FETCH',
  OPEN_FETCHER: 'OPEN_FETCHER',
  CLOSE_FETCHER: 'CLOSE_FETCHER',
  FETCHED: 'FETCHED',

  // similarDate
  GET_SIMILAR_DATE: 'GET_SIMILAR_DATE',
  UPDATE_SELECT_DATE: 'UPDATE_SELECT_DATE',
  UPDATE_SELECT_INDEX: 'UPDATE_SELECT_INDEX',

  //timeSlider
  UPDATE_CURRENT_TIME: 'UPDATE_CURRENT_TIME',

  //graph
  TOGGLE_LEFT_BAR: 'TOGGLE_LEFT_BAR',
  OPEN_LEFT_BAR: 'OPEN_LEFT_BAR',
  CLOSE_LEFT_BAR: 'CLOSE_LEFT_BAR',
  INIT_AROUND_STATIONS: 'INIT_AROUND_STATIONS',
  GET_AROUND_STATIONS: 'GET_AROUND_STATIONS',
  GET_PEOPLEFLOW_STAY: 'GET_PEOPLEFLOW_STAY',
  GET_STATION_CONGESTION_LEVEL: 'GET_STATION_CONGESTION_LEVEL',
  GET_STATION_CONGESTION_RATIO: 'GET_STATION_CONGESTION_RATIO',
  DELETE_CONGESTION_ERROR_ALL: 'DELETE_CONGESTION_ERROR_ALL',

  //alert
  ADD_ALERT: 'ADD_ALERT',
  DELETE_ALERT_ALL: 'DELETE_ALERT_ALL',
};

export default ActionTypes;
