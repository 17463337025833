import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as fetcherActions from '../actions/fetcher';
import createRootReducer from '../reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      obj: Record<string, any>
    ) => Function;
  }
}

const rootReducer = createRootReducer();

export type RootState = ReturnType<typeof rootReducer>;

const configureStore = () => {
  const actionCreators = {
    ...fetcherActions,
  };

  // Redux Devtool Extension : Browser で Redux をデバッグしやすくする
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Options: http://extension.remotedev.io/docs/API/Arguments.html
        actionCreators,
      })
    : compose;
  /* eslint-enable no-underscore-dangle */

  const middleware = applyMiddleware(thunk);

  const enhancer = composeEnhancers(middleware);

  const store = createStore(rootReducer, enhancer);

  return store;
};

export default configureStore;
