import { ArcLayer } from 'deck.gl';

const vsDeclaration = `
attribute float instanceTailLength;
varying float vArcLength;
varying float vTailLength;`;

const vsMain = `
vArcLength = distance(source, target);
vTailLength = instanceTailLength;
`;

const fsDeclaration = `
uniform float timestamp;
uniform float animationSpeed;

vec4 baseColor = vec4(1.0, 1.0, 1.0, 0.1);

varying float vArcLength;
varying float vTailLength;`;

const fsColorFilter = `
float tripDuration = vArcLength / animationSpeed;
float flightInterval = 1.0;
float r = mod(geometry.uv.x, flightInterval);

// Head of the trip (alpha = 1.0)
float rMax = mod(fract(timestamp / tripDuration), flightInterval);
// Tail of the trip (alpha = 0.0)
float rMin = rMax - vTailLength / vArcLength;
// Two consecutive trips can overlap
float alpha = (r > rMax ? 0.0 : smoothstep(rMin, rMax, r)) + smoothstep(rMin + flightInterval, rMax + flightInterval, r);
if (alpha == 0.0) {
  discard;
}
color.a *= alpha;
`;

const defaultProps = {
  ...ArcLayer.defaultProps,
  animationSpeed: { type: 'number', min: 0, value: 1 },
  getTailLength: { type: 'accessor', value: 1 },
};
class AnimatedArcLayer extends ArcLayer {
  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = {
      'vs:#decl': vsDeclaration,
      'vs:#main-end': vsMain,
      'fs:#decl': fsDeclaration,
      'fs:DECKGL_FILTER_COLOR': fsColorFilter,
    };
    return shaders;
  }

  initializeState(params) {
    super.initializeState(params);

    this.getAttributeManager().addInstanced({
      instanceTailLength: {
        size: 1,
        accessor: 'getTailLength',
        defaultValue: 1,
      },
    });
  }

  draw(opts) {
    this.state.model.setUniforms({
      animationSpeed: this.props.animationSpeed,
      timestamp: (Date.now() / 1000) % 86400,
    });
    super.draw(opts);

    // By default, the needsRedraw flag is cleared at each render. We want the layer to continue
    // refreshing.
    this.setNeedsRedraw();
  }
}

AnimatedArcLayer.layerName = 'AnimatedArcLayer';
AnimatedArcLayer.defaultProps = defaultProps;

export default AnimatedArcLayer;
