export const AxisStyle = {
  line: { stroke: '#FFF' },
  ticks: { stroke: '#FFF' },
  text: { stroke: 'none', fill: '#FFF', fontSize: 8 },
};

export const VerticalBarStyle = {
  stroke: '#FFF',
  strokeWidth: 0.1,
};
