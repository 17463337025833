import { Reducer } from 'redux';
import { ActionType } from '../types';
import ActionTypes from '../constants/actionTypes';
import { produce } from 'immer';

const leftBar: Reducer<boolean, ActionType> = produce(
  (draft: boolean = false, action) => {
    switch (action.type) {
      case ActionTypes.TOGGLE_LEFT_BAR:
        const isOpen: boolean = action.payload;
        return !isOpen;
      case ActionTypes.OPEN_LEFT_BAR:
        return true;
      case ActionTypes.CLOSE_LEFT_BAR:
        return false;
    }
    return draft;
  }
);

export default leftBar;
