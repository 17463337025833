import React from 'react';
import {
  StyledCard,
  StyledDiv,
  StyledDateTypography,
  StyledTimeTypography,
} from './index.styles';

import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

export type TimeDisplayProps = {
  timeStamp: Date;
};

const TimeDisplay: React.FC<TimeDisplayProps> = (props) => {
  const date = format(props.timeStamp, 'yyyy/MM/dd', { locale: ja });
  const time = format(props.timeStamp, 'HH:mm', { locale: ja });

  return (
    <StyledCard>
      <StyledDiv>
        <StyledDateTypography>{date}</StyledDateTypography>
        <StyledTimeTypography>{time}</StyledTimeTypography>
      </StyledDiv>
    </StyledCard>
  );
};

export default TimeDisplay;
