import { ActionType, StationListType } from '../types';
import ActionTypes from '../constants/actionTypes';
import { produce } from 'immer';
import { Reducer } from 'redux';
import { format } from 'date-fns';
import { geoToH3 } from 'h3-js';
import station_list from '../assets/json/stations.json';

const HEXAGON_RESOLUTION = Number(process.env.REACT_APP_HEXAGON_RESOLUTION);

export type FetcherStateType = {
  stationId: string;
  latitude: number;
  longitude: number;
  startDateTime: string;
  endDateTime: string;
  isOpen: boolean;
  isFetched: boolean;
  stationList: Array<StationListType>;
};

const defaultLngLat:
  | Array<string>
  | undefined = process.env.REACT_APP_DEFAULT_LOCATION?.split(',');

export const initialState: FetcherStateType = {
  stationId: 'ed14c0ed-73e3-4ddb-a106-779354a58fee',
  longitude: defaultLngLat?.length === 2 ? Number(defaultLngLat[0]) : 139.7625,
  latitude: defaultLngLat?.length === 2 ? Number(defaultLngLat[1]) : 35.6753,
  startDateTime: format(
    new Date(String(process.env.REACT_APP_VALID_DATE_FROM)),
    "yyyy-MM-dd'T'00:00:00"
  ),
  endDateTime: format(
    new Date(String(process.env.REACT_APP_VALID_DATE_FROM)),
    "yyyy-MM-dd'T'23:59:59"
  ),
  isOpen: true,
  isFetched: false,
  stationList: station_list,
};

const fetcher: Reducer<FetcherStateType, ActionType> = produce(
  (draft = initialState, action: ActionType) => {
    switch (action.type) {
      case ActionTypes.SET_STATION: {
        const { stationId, longitude, latitude } = action.payload;
        const newH3Index = action.payload.h3Index;
        const oldH3Index = geoToH3(
          draft['latitude'],
          draft['longitude'],
          HEXAGON_RESOLUTION
        );
        if (oldH3Index === newH3Index) {
          return draft;
        } else {
          return {
            ...draft,
            stationId,
            longitude,
            latitude,
          };
        }
      }
      case ActionTypes.UPDATE_STATION: {
        const { stationId, latitude, longitude } = action.payload;
        return { ...draft, stationId, latitude, longitude };
      }
      case ActionTypes.UPDATE_DATES: {
        const { startDateTime, endDateTime } = action.payload;
        if (typeof startDateTime === 'string') {
          draft['startDateTime'] = startDateTime;
        }
        if (typeof endDateTime === 'string') {
          draft['endDateTime'] = endDateTime;
        }
        return draft;
      }
      case ActionTypes.OPEN_FETCHER: {
        draft['isOpen'] = true;
        return draft;
      }
      case ActionTypes.CLOSE_FETCHER: {
        draft['isOpen'] = false;
        return draft;
      }
      case ActionTypes.FETCHED: {
        draft['isFetched'] = true;
        return draft;
      }
      default:
        return draft;
    }
  }
);

export default fetcher;
