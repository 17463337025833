import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { HelpTooltip } from '../atoms/CustomizedTooltip';

import { StyledDiv } from './FetcherContentTitle.styles';

type Props = {
  title: string;
  helpText: { title: string; subtitle?: string };
};

const FetcherContentTitle: React.FC<Props> = (props) => {
  const { title, helpText } = props;
  return (
    <StyledDiv>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
      >
        <Grid container item direction='row' justify='flex-start' xs={10}>
          <Typography variant='subtitle1' component='h2'>
            {title}
          </Typography>
        </Grid>
        {helpText ? (
          <Grid container item direction='row' justify='flex-end' xs={2}>
            <HelpTooltip
              title={helpText.title}
              content={
                <Typography variant='subtitle2' color='inherit'>
                  {helpText.subtitle}
                </Typography>
              }
              placement='bottom-end'
            >
              <HelpOutlineIcon color='inherit' fontSize='inherit' />
            </HelpTooltip>
          </Grid>
        ) : null}
      </Grid>
    </StyledDiv>
  );
};

export default FetcherContentTitle;
