import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import { drawerWidth } from './KPILists.styles';

type DrawerToggleType = {
  open: boolean;
};

export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#fff',
    },
  },
});

export const StyledDiv = styled.div.attrs((props: DrawerToggleType) => ({
  open: props.open,
}))`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  bottom: 38px;
  right: calc(10%);
  width: calc(80% - ${(props) => (props.open ? drawerWidth : `0px`)});
  transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
`;
